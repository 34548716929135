import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import darkLogo from "../graphics/logo-next-lighting-dark.png";
import Line from "../components_en/Line";
import '../styles/App.css';
import '../styles/Odm.css';

import icon1 from "../graphics/icons/icon-01-start.png";
import icon2 from "../graphics/icons/icon-02-next.png";
import icon3 from "../graphics/icons/icon-03-next.png";
import icon4 from "../graphics/icons/icon-04-next.png";
import icon5 from "../graphics/icons/icon-05-next.png";
import icon6 from "../graphics/icons/icon-06-next.png";

function Odm () {
        return(
            <>
                <Header />
                    <TitleSection h1={'ODM / OEM'} h2={''} height='280px'/>
                    <div id='odm-secondSection'>
                        <div id='odm-secondSectionCentered'>
                            <img id='odm-secondSectionLogo' src={darkLogo} alt="Next lighting"/>
                            <span className='odm-firstDescription'>Next Lighting provides two types of services: <span className='boldText'>OEM</span> - when we produce a product for you according to your design, in your brand, and <span className='boldText'>ODM</span> - when we present the product concept, develop your product, perform engineering samples and industrialization.</span>
                        </div>
                    </div>
                    <div id='odm-thirdSection'>
                        <div className='odm-thirdSectionBox'>
                            <h4>ODM – Original Design and Manufacture a product, as specified by you, in your brand.</h4>
                            <Line color1='#dfc08d' color2='#69dbe1' color3='white' lineWidth='200px' lineHeight='2px' />
                            <div className='odm-thirdSectionTableBox'>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Concept design</span>
                                <img className='odm-thirdSectionTableIcon' src={icon1} alt='Concept design'/>
                                Create concept & product platform 
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Development</span>
                                <img className='odm-thirdSectionTableIcon' src={icon2} alt='Development'/>
                                End-to-end product development 
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Prototyping</span>
                                <img className='odm-thirdSectionTableIcon' src={icon3} alt='Prototyping'/>
                                Engineering samples and prototypes 
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Industrialization</span>
                                <img className='odm-thirdSectionTableIcon' src={icon4} alt='Industrialization'/>
                                Design for manufacturing and assembly
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Production </span>
                                <img className='odm-thirdSectionTableIcon' src={icon5} alt='Production'/>
                                LEAN production 
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Logistics</span>
                                <img className='odm-thirdSectionTableIcon' src={icon6} alt='Logistics'/>
                                Reliable delivery 
                            </div>
                                </div>
                            </div>
                        <div className='odm-thirdSectionBox'>
                            <h4>OEM – Original Equipment Manufacturing – we produce for you the product according to your design, in your brand. </h4>
                            <Line color1='#dfc08d' color2='#69dbe1' color3='white' lineWidth='200px' lineHeight='2px' />
                            <div className='odm-thirdSectionTableBox'>
                                <div className="odm-thirdSectionTableCell6">
                                    <span className='boldText'>Prototyping </span>
                                    <img className='odm-thirdSectionTableIcon' src={icon6} alt='Prototyping'/>
                                    Engineering samples and prototypes 
                                </div>
                                <div className="odm-thirdSectionTableCell6">
                                    <span className='boldText'>Industrialization</span>
                                    <img className='odm-thirdSectionTableIcon' src={icon4} alt='Industrialization'/>
                                    Design for manufacturing and assembly
                                </div>
                                <div className="odm-thirdSectionTableCell6">
                                    <span className='boldText'>Production </span>
                                    <img className='odm-thirdSectionTableIcon' alt='Production' src={icon5}/>
                                    LEAN production 
                                </div>
                                <div className="odm-thirdSectionTableCell6">
                                    <span className='boldText'>Logistics</span>
                                    <img className='odm-thirdSectionTableIcon' alt='Logistics' src={icon6}/>
                                    Reliable delivery 
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        );
}

export default Odm;