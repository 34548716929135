import React from "react";
import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import ProductBox from "../components_en/ProductBox";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import longProWhiteImg from '../graphics/products/long-pro-white.jpg';

function LongPro () {
    return(
        <>
        <Header/>
        <TitleSection h1={'LONGPRO'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <span className="productCategories-productGroupDescription">
            This is an innovative digitally controlled linear LED lamp that combines a twin-tube design with a lighting fixture. Creating two products in one enables an ultra-fast lamp assembly without the need for disassembly. External, possible dual-sided connectivity to the power source accelerates the process, making it crucial for installations and disassemblies in large facilities with high ceilings. The Long Pro is flicker-free, significantly improving animal well-being and tranquility. The use of modern controllers allows for brightness control tailored to specific needs, making Long Pro suitable for various types of animal husbandry.
            </span>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/animal-centric-lighting/longpro-products/longpro-white'} productImg={longProWhiteImg} productTitle='LongPro White' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/longpro-products/longpro-white-red-digital'} productImg={longProWhiteImg} productTitle='LongPro White + Red Digital' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/longpro-products/longpro-white-blue-digital'} productImg={longProWhiteImg} productTitle='LongPro White + Blue Digital' productCategory='productCategories-lamps'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default LongPro;