import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import skyPanelImg from '../graphics/products/skyPanel.jpg';
import longProHumanImg from '../graphics/products/longProHuman.jpg';
import ProductBox from "../components_en/ProductBox";


function HumanCentricLighting () {
    return(
        <>
        <Header/>
        <TitleSection h1={'HUMAN CENTRIC LIGHTING'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <div className='productCategories-textParagraphRowBox'>
                <p className='productCategories-textParagraph'>
                Human Centric Lighting (HCL) to koncepcja oświetlenia wnętrz ukierunkowana na człowieka, która bierze pod uwagę nie tylko wizualne, ale także emocjonalne i niewizualne efekty światła. Pod pojęciem HCL zwykle rozumie się dostosowanie oświetlenia do naturalnego przebiegu światła dziennego. Oprócz natężenia oświetlenia istotna jest również zmiana temperatury barwowej światła. Wieczorem oświetlenie zmniejsza się. W przypadku HCL spektrum barw światła powinno zmieniać się w ciągu dnia, podobnie jak w przypadku światła naturalnego: wysokie składowe niebieskiego w godzinach porannych, cieplejsze światło o wschodzie słońca, zwiększanie składowych niebieskich w południe, zmniejszanie się składowych niebieskich w kierunku wieczora i wysokie składowe niebieskie przy słabym oświetleniu po zachodzie słońca (niebieska godzina). Zmieniające się światło działa za pośrednictwem światłoczułych komórek zwojowych oka zawierających melanopsynę bezpośrednio na synchronizację zegara biologicznego i uwalnianie hormonu snu melatoniny z szyszynki. [1]
                </p>
            </div>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/human-centric-lighting/sky-panel'} productImg={skyPanelImg} productTitle='Panel niebo' productCategory='productCategories-Human'/>
                <ProductBox productPageHref={'/products/human-centric-lighting/longpro-hcl'} productImg={longProHumanImg} productTitle='LongPro HCL' productCategory='productCategories-Human'/>
            </div>
            <p style={{fontSize: "12px", width: "900px", marginBottom: "20px", fontSize: "12px"}}>[1] Lichtmanagement als Zukunftstechnologie: Der fünfte Sinn des Auges und wie wir ihn nutzen können. vom Fraunhofer-Institut für Arbeitswirtschaft und Organisation IAO, abgerufen am 2. Februar 2020"</p>
        </div>
        <Footer/>
        </>
    );
}

export default HumanCentricLighting;