import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import noiseIconImg from '../../graphics/icons/noiseIcon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import RemoteControllerIconImg from '../../graphics/icons/RemoteControllerIcon.png';
import filterIconImg from '../../graphics/icons/filterIcon.jpg';
import UVC_IconImg from  '../../graphics/icons/UVC_icon.jpg';
import MinusIconImg from  '../../graphics/icons/minusIcon.png';
import AirPaint40Img from '../../graphics/products/AirPaint40.jpg';

import AirPaintM1 from '../../graphics/products/AirPaintM/AirPaintM1.jpg';
import AirPaintM2 from '../../graphics/products/AirPaintM/AirPaintM2.jpg';
import AirPaintM3 from '../../graphics/products/AirPaintM/AirPaintM4.jpg';
import AirPaintM4 from '../../graphics/products/AirPaintM/AirPaintM4.jpg';
import AirPaintM5 from '../../graphics/products/AirPaintM/AirPaintM5.jpg';

import ProductSlider from "../../components_en/ProductSlider";

import Img1Img from '../../graphics/products/ProductsImgs/Img1.jpg';
import Img2Img from '../../graphics/products/ProductsImgs/Img2.jpg';
import Img3Img from '../../graphics/products/ProductsImgs/Img3.jpg';


function AirPaint40 () {
    return(
        <>
            <Header/>
            <TitleSection h1={'AirPaint®40'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div style={{width: "80%"}} className="productCategories-productCategoriesBigBoxTop">
                <img style={{height: "250px", marginTop: "20px"}} src={Img1Img} />
                <img style={{height: "250px", marginTop: "20px"}} src={Img2Img} />
                <img style={{height: "250px", marginTop: "20px"}} src={Img3Img} />
            </div>
            <div className='productCategories-productPageContainer'>
                <h4>Easy insallation</h4>
                <div className='productCategories-airPaintMount'>
                    <img className='airPaintM' src={AirPaintM1}/>
                    <img className='airPaintM' src={AirPaintM2}/>
                    <img className='airPaintM' src={AirPaintM3}/>
                    <img className='airPaintM' src={AirPaintM4}/>
                    <img className='airPaintM' src={AirPaintM5}/>
                </div>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Kills viruses, bacteria and mould</li> 
                                <li>Reduces unpleasant odours</li>
                                <li>Improves well-being through negative air ionization</li> 
                                <li>Eliminates hazardous volatile substances
                                    (formaldehydes, ammonia, nitrogen oxides, etc.)</li> 
                                <li>Efficiency 40m3/h suitable for up to 20m2</li> 
                                <li>3 modes: silent, normal, boost with remote control</li> 
                                <li>Slim design to install behind pictures and paintings</li> 
                            <   li>Microprocessor controller with advanced control of purification modules 
                                    working time, temperature and flow level </li>
                                <li>Lifetime 50 000h – no maintenance for 17 years (if used 8h/day) </li>
                                <li>Eco design – no mercury lamps, almost eliminated plastics</li>
                                <li>100% safety: no ozone produced, closed UVC reactor with photo-bio-safety certificate</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[AirPaint40Img]}/>
                </div>
                <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Dimensions:</p>
                            <p>H: 600mm</p>
                            <p>W: 180mm</p>
                            <p>D: 80mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                            <p>Weight: 2.2kg</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Voltage: 12-19V DC</p>
                            <p>Power: 60W</p>
                            <p>IP code: IP20 (IEC 60529)</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={noiseIconImg} alt='icon'/>
                            <p>Noise level: {'<'} 40dB </p>
                            <p>Air flow: 3 modes, up to 40m3/h</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                            <p>UVC SOURCE: UVC LED Module 50khrs</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={RemoteControllerIconImg} alt='icon'/>
                            <p>MCU Controller: Lifetime, efficiency, air flow</p>
                            <p>Remote control: yes</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Disinfection technologies:</p>
                            <ul>
                                <li>PP filter or HEPA filter (option)</li>
                                <li>UVC disinfection</li>
                                <li>PCO disinfection</li>
                            </ul>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={filterIconImg} alt='icon'/>
                            <img className='productParametersIcon' src={UVC_IconImg} alt='icon'/>
                            <img className='productParametersIcon' src={MinusIconImg} alt='icon'/>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default AirPaint40;