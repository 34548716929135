import React from "react";
import logoCircle from '../graphics/circle.png';
import PageTopNav from "./PageTopNav";

class TitleSection extends React.Component {
    render () {
        return(
            <>
                <div id="firstSection" style={{height: this.props.height}}>
                <div className={'pageTitle'}>
                    <h1 className='whiteText'>{this.props.h1}</h1>
                    <h3 className='whiteText'>{this.props.h2}</h3>
                </div>
                </div>
                <div id='circleBox'>
                    <img id='middleCircle' src={logoCircle} alt="Next Lighting"/>
                </div>
                <PageTopNav />
            </>
        );
    }
}
export default TitleSection;