import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_en/TitleSection";
import darkLogo from "../graphics/logo-next-lighting-dark.png";
import Line from "../components_en/Line";
import '../styles/App.css';
import '../styles/Odm.css';

import icon1 from "../graphics/icons/icon-01-start.png";
import icon2 from "../graphics/icons/icon-02-next.png";
import icon3 from "../graphics/icons/icon-03-next.png";
import icon4 from "../graphics/icons/icon-04-next.png";
import icon5 from "../graphics/icons/icon-05-next.png";
import icon6 from "../graphics/icons/icon-06-next.png";

function Odm () {
        return(
            <>
                <Header />
                    <TitleSection h1={'ODM / OEM'} h2={''} height='280px'/>
                    <div id='odm-secondSection'>
                        <div id='odm-secondSectionCentered'>
                            <img id='odm-secondSectionLogo' src={darkLogo} alt="Next lighting"/>
                            <span className='odm-firstDescription'>Next Lighting świadczy dwa rodzaje usług: OEM - gdy produkujemy produkt dla Ciebie zgodnie z Twoim projektem, pod Twoją marką, oraz ODM - gdy przedstawiamy koncepcję produktu, rozwijamy Twój produkt, wykonujemy próbki inżynieryjne  i przygotowujemy go do produkcji.</span>
                        </div>
                    </div>
                    <div id='odm-thirdSection'>
                        <div className='odm-thirdSectionBox'>
                            <h4>ODM – Oryginalne Projektowanie i Produkcja produktu, zgodnie z Twoimi specyfikacjami, pod Twoją marką.</h4>
                            <Line color1='#dfc08d' color2='#69dbe1' color3='white' lineWidth='200px' lineHeight='2px' />
                            <div className='odm-thirdSectionTableBox'>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Projekt koncepcyjny</span>
                                <img className='odm-thirdSectionTableIcon' src={icon1} alt='Projekt koncepcyjny'/>
                                Tworzenie koncepcji i platformy produktowej 
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Rozwój</span>
                                <img className='odm-thirdSectionTableIcon' src={icon2} alt='Development'/>
                                Rozwinięty proces rozwoju produktu 
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Prototypowanie</span>
                                <img className='odm-thirdSectionTableIcon' src={icon3} alt='Prototyping'/>
                                Próbki techniczne i prototypy 
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Produkcja</span>
                                <img className='odm-thirdSectionTableIcon' src={icon4} alt='Industrialization'/>
                                Projektowanie dla produkcji i montażu 
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Produkcja </span>
                                <img className='odm-thirdSectionTableIcon' src={icon5} alt='Production'/>
                                Produkcja zorientowana na LEAN 
                            </div>
                            <div className="odm-thirdSectionTableCell6">
                                <span className='boldText'>Logistyka</span>
                                <img className='odm-thirdSectionTableIcon' src={icon6} alt='Logistics'/>
                                Pewna dostawa
                            </div>
                                </div>
                            </div>
                        <div className='odm-thirdSectionBox'>
                            <h4>OEM – Produkcja Oryginalnego Sprzętu – produkujemy dla Ciebie produkt zgodnie z Twoim projektem, pod Twoją marką. </h4>
                            <Line color1='#dfc08d' color2='#69dbe1' color3='white' lineWidth='200px' lineHeight='2px' />
                            <div className='odm-thirdSectionTableBox'>
                                <div className="odm-thirdSectionTableCell6">
                                    <span className='boldText'>Prototypowanie  </span>
                                    <img className='odm-thirdSectionTableIcon' src={icon6} alt='Prototyping'/>
                                    Próbki techniczne i prototypy
                                </div>
                                <div className="odm-thirdSectionTableCell6">
                                    <span className='boldText'>Uprzemysłowienie</span>
                                    <img className='odm-thirdSectionTableIcon' src={icon4} alt='Industrialization'/>
                                    Projektowanie dla produkcji i montażu
                                </div>
                                <div className="odm-thirdSectionTableCell6">
                                    <span className='boldText'>Produkcja</span>
                                    <img className='odm-thirdSectionTableIcon' alt='Production' src={icon5}/>
                                    Produkcja zorientowana na LEAN
                                </div>
                                <div className="odm-thirdSectionTableCell6">
                                    <span className='boldText'>Logistyka</span>
                                    <img className='odm-thirdSectionTableIcon' alt='Logistics' src={icon6}/>
                                    Pewna dostawa
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        );
}

export default Odm;