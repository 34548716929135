import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import AirBarSR40Img from '../graphics/products/AirPanel40.jpg';
import AirBarTransportImg from '../graphics/products/AirBar40Transport.jpg';
import AirPaintImg from '../graphics/products/AirPaint40.png';
import AirPanelImg from '../graphics/products/AirPanel40-80-dark.png';

import ProductBox from "../components_en/ProductBox";


function LEDPurifiers () {
    return(
        <>
        <Header/>
        <TitleSection h1={'UV AIR DISINFECTION'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
        <span className='productCategories-productGroupDescription'>
        It operates based on the airflow exposed to UV-C radiation with a wavelength of 254 nm, reducing the population of microorganisms in the purified air. During operation, people can be in the immediate vicinity of the device, as it does not emit harmful UV-C radiation or ozone. The total power of the UV-C lamps is 110W. The device is equipped with a reflector that ensures a 95% reflection of UV-C light, compared to standard solutions that achieve only 70%.
Two highly efficient and quiet fans provide the airflow. Depending on the equipment, the device can disinfect from 100 m3/h to 250 m3/h of air.
Optionally, the UV-C air purifier can be equipped with a HEPA filter and a PCO module.
                </span>
                <span className='productCategories-productGroupDescription'>
                HEPA Filter:
By default, the device comes with a dust-preventing filter, which aims to prevent the interior of the reactor from becoming dirty, potentially reducing the disinfection efficiency. Optionally, the device can be equipped with a cylindrical HEPA filter that captures up to 99.95% of particles with a size ≥0.3 µm.

                </span>
                <span className='productCategories-productGroupDescription'>
                PCO - Photocatalytic Oxidation:
This is a highly efficient air purification technology capable of destroying particles as small as 0.1 nanometers and absorbing some gaseous pollutants (e.g., toxins like cigarette smoke, benzene, or formaldehyde). The use of the PCO module provides even greater effectiveness than HEPA filters, which are typically rated only for particles with a size of 0.3 μm or larger [EN1822 Standard]. Through the illumination of the TiO2 module with UV-A light, in the presence of humidity and oxygen, ions (+OH) are generated, which destroy microorganisms, volatile organic compounds (VOC), and chemically active compounds (CAC: 13463-67-7).
                </span>
            <h3>LED purifiers</h3>
            <div id='productCategories-productsGrid'>              
                <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airbar-40-transport'} productImg={AirBarTransportImg} productTitle='AirBar®40 Transport' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airpaint-40'} productImg={AirPaintImg} productTitle='AirPaint®40' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airpanel-80'} productImg={AirPanelImg} productTitle='AirPanel®80' productCategory='productCategories-UVC'/>    
                <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airpanel-40'} productImg={AirBarSR40Img} productTitle='AirPanel®40' productSubtitle='' productCategory='productCategories-UVC'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default LEDPurifiers;