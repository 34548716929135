import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import pro15Img from "../../graphics/products/pro15.png";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import ProductSlider from "../../components_en/ProductSlider";


function Pro15Plus () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Pro15 plus'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className="nonAvailable">
                Available Q4/2024
            </div>
            <div className='productCategories-productPageContainer'>
                <span className='productCategories-productDescription'>
                        Pro15 Plus is an enhanced version of the PRO-15, which additionally features a built-in 24-hour clock and the capability to control SLS (Sunrise & Sunset Simulation) using an internal timer.
                <br></br><br></br>
                Power adjustment is achieved through phase-cutting, also known as trailing edge. PRO-15 is designed to handle resistive and capacitive loads. It allows for manual and automatic lighting control, using a 0-10V signal, or even through the connection of a single-pole switch. It's possible to create dimming packages to increase the total power capacity. The dimmer supports dimmable light sources such as incandescent bulbs, 230V AC halogen bulbs, low-voltage halogen bulbs powered by electronic transformers, dimmable CFLs, cold cathode fluorescent lamps (CCFL), and LED bulbs. Installing the dimmer is straightforward and fast, and its operation is 100% intuitive. It comes in a TH 35mm rail-mountable housing. Its maximum load capacity is 1500W. The digital display provides information about its status, security features, and its functions can be programmed using the buttons. It also has built-in electronic protections against overload, short circuits, overheating, and network surges. Its software allows for simulating sunrise and sunset, known as SLS (Sunrise & Sunset Simulation).        
                </span>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Transistor lighting regulator, maximum load 1500W- Manual adjustment or via an external 0-10V controller (PC, PLC, SLS1+, etc.)</li>
                                <li>Possibility of parallel connection to increase the total power</li>
                                <li>Optical and acoustic signaling of errors- Alarm output- Additional functionality: light cycle function (sunrise and sunset)</li>
                                <li>Easy, intuitive operation</li>
                                <li>Mounting method: DIN-35 rail</li>
                            <p>Regulates different types of light source:</p>
                            <ul className="productCategories-secondSectionList">
                                <li>Incandescent and 230Vac halogen bulbs</li>
                                <li>Low voltage halogen bulbs powered by an electronic transformer </li>
                                <li>Dimmable CFL and CCFL energy saving bulbs</li>
                                <li> Dimmable LED bulbs</li>
                            </ul>
                            <li>Status and error information on the LED display</li>
                            <li>Alarm output</li>
                            <li>Buil-in SLS function alows to set sunrise and sunset simulation</li>
                            <li>Easy, intuitive operation with the possibility of your own configuration</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[pro15Img]}/>
                </div>
                <div className='productCategories-productParameters'>
                    <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Dimensions (whole system approx.):</p>
                            <p>H:  85mm</p>
                            <p>W: 105mm</p>
                            <p>D: 60mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Weight: 280g</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Voltage: 230V 50/60Hz</p>
                            <p>Load Power: 1500VA max</p>
                            <p>Mounting: DIN-35 Rail</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                                <p>Build-in Protections:</p>
                                <p>- Over-Load</p>
                                <p>- Short-circuit</p>
                                <p>- Over-temperature</p>
                                <p>- Over-voltage</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Standards:</p>
                            <p>Safety EN 60598-1, EN 62471</p>
                            <p>EMC EN 55022</p>
                            <p>The Dimmer is safe to use under conditions -10 - 45˚</p>
                            <p>IP20 (IEC 60529)</p>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                    </div>
                    </div>
        </div>
        <Footer/>
        </>
    );
}

export default Pro15Plus;