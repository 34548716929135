import '../styles/App.css';
import '../styles/Company.css';
import Header from '../components_en/Header';
import logoCircle from '../graphics/circle.png';
import Footer from '../components_en/Footer';
import logoDark from '../graphics/logo-next-lighting-dark.png';
import Line from '../components_en/Line';
import rndGraphics from '../graphics/r-d-team.jpg';
import manofacturingGraphics from '../graphics/manufacturing.jpg';
import certificationGraphics from '../graphics/certification.jpg';

function Company() {
  return (
    <>
        <Header />
        <>
          <div id="firstSection" style={{height: '650px'}}>
          <div className={'pageTitle'}>
              <h1 className='whiteText company-bigTitle'>NEXT LIGHTING</h1>
              <h3 className='whiteText company-bigTitle2'>Development and manufacturing</h3>
          </div>
          </div>
          <div id='circleBox'>
              <img id='middleCircle' src={logoCircle} alt="Next Lighting"/>
          </div>
        </>
        <div id='company-secondSection'>
          <div id='company-secondSectionCentered'>
            <div id='company-secondSectionLogoBox'>
              <img id='company-secondSectionLogo' src={logoDark} alt="Next Lighting" title="Next Lighting" />
            </div>
            <div id='company-secondSectionDescription'>
              <p className='company-secondSectionParagraph'>
                NEXT Lighting focuses on development and manufacturing of customer-specific intelligent lighting systems and UV solutions. Our expertise in the fields of lighting, UV technology, IoT makes us reliable and flexible OEM and ODM partner. 
              </p>
              <p className='company-secondSectionParagraph'>
                From concept design, prototyping, industrialization to mass production, quality assurance and logistics support, we cover the entire service chain and help you turn your idea into a ready for market product. We are located in Torun, in central part of Poland. Our team includes nearly 20 engineers higly skilled in lighting and UV appliances design. 
              </p>
            </div>
          </div>
        </div>
        <div id='company-thirdSection'>
            <div id='company-thirdSectionCentered'>
                <h2>Why us?</h2>
                <Line color1='#081424' color2='#69dbe1' color3='#eeeeee' lineWidth='200px' lineHeight='5px' />
                <div id='company-thirdSectionBoxContainer'>
                  <div className='company-thirdContainerBox'>
                    <img className='company-thirdSectionBoxImg' src={rndGraphics} alt='R&D'/>
                    <h3>R&D Team</h3>
                    <Line color1='#081424' color2='#69dbe1' color3='#eeeeee' lineWidth='100px' lineHeight='2px' />
                    <p className='company-thirdContainerBoxText'>Imagine energized team, where everyone can use his passion to create not only the final product but a masterpiece to make our customer delighted. </p>
                    <p className='company-thirdContainerBoxText'>From last 20 years our engineers have been developing new products and leading the projects in such companies as Philips, Whirlpool, Jabil, Signify, Indesit & Apator.</p>
                  </div>
                  <div className='company-thirdContainerBox'>
                    <img className='company-thirdSectionBoxImg' src={certificationGraphics} alt='Certification'/>
                    <h3>Certification</h3>
                    <Line color1='#081424' color2='#69dbe1' color3='#eeeeee' lineWidth='100px' lineHeight='2px' />
                    <p className='company-thirdContainerBoxText'>Quick validation process and time to market thanks to our laboratories:</p>
                    <ul className="company-thirdSectionBoxList">
                      <li>Photometrical laboratory</li>
                      <li>Photobiological safety</li>
                      <li>Electrical Labs & EMI Lab</li>
                      <li>Mechanical & Safety Lab</li>
                    </ul>
                  </div>
                  <div className='company-thirdContainerBox'>
                    <img className='company-thirdSectionBoxImg' src={manofacturingGraphics} alt='Manofacturing'/>
                    <h3>Manufacturing</h3>
                    <Line color1='#081424' color2='#69dbe1' color3='#eeeeee' lineWidth='100px' lineHeight='2px' />
                    <p className='company-thirdContainerBoxText'>Three elements make our factory unique in quality, reliability and cost:</p>
                    <ul className="company-thirdSectionBoxList">
                      <li>LEAN MANUFACTURING - mindset focused on waste elimination</li>
                      <li>INDUSTRY 4.0 - intelligent & agile automation and systems</li>
                      <li>VERTICAL INTEGRATION - SMT, THT, assembly and strategic partners in metals & optics</li>
                    </ul>
                  </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  );
}

export default Company;
