import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import AirBarSR100VImg from '../graphics/products/AirBar100V.jpg';
import AirBarSR100HImg from '../graphics/products/AirBarSR100H.jpg';
import AirBarDR250HImg from '../graphics/products/AirBarSR100H.jpg';
import AirBarDR250BImg from '../graphics/products/DR250B.jpg';
import AirBarDR250VImg from '../graphics/products/AirBarDR250VBlack.png'
import AirBarSR40Img from '../graphics/products/AirPanel40.jpg';
import AirPaintImg from '../graphics/products/AirPaint40.png';
import AirPanelImg from '../graphics/products/AirPanel40-80-dark.png';



import ProductBox from "../components_en/ProductBox";


function HomeHotelClinicGym () {
    return(
        <>
        <Header/>
        <TitleSection h1={'Domy/Hotele/Siłownie/Przychodnie'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <span style={{width: "70%"}}className='productCategories-productGroupDescription'>
            Nasze urządzenia wykorzystują przepływ powietrza w oparciu o promieniowanie UV-C o długości fali 254 nm, redukujące populację mikroorganizmów w oczyszczonym powietrzu. Podczas pracy w bezpośrednim sąsiedztwie urządzenia mogą znajdować się ludzie, gdyż nie emituje ono szkodliwego promieniowania UV-C ani ozonu. Urządzenie wyposażone jest w reflektor zapewniający 95% odbicie światła UV-C w porównaniu do standardowych rozwiązań, które osiągają jedynie 70%. W zależności od rodzaju źródłem promieniowania są świetlówki lub lampy LED UV-C. Przepływ powietrza zapewniają dwa bardzo wydajne i ciche wentylatory. W zależności od wyposażenia urządzenie może dezynfekować od 40 m3/h do 250 m3/h powietrza. Opcjonalnie oczyszczacz powietrza UV-C może być wyposażony w filtr HEPA i moduł PCO.
            </span>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-sr100v'} productImg={AirBarSR100VImg} productTitle='AirBar®' productSubtitle='SR100V' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airpanel-40'} productImg={AirBarSR40Img} productTitle='AirPanel®40' productSubtitle='' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airpanel-80'} productImg={AirPanelImg} productTitle='AirPanel®80' productCategory='productCategories-UVC'/>  
                <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airpaint-40'} productImg={AirPaintImg} productTitle='AirPaint®40' productCategory='productCategories-UVC'/>  
                <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-sr100h'} productImg={AirBarSR100HImg} productTitle='AirBar®'  productSubtitle='SR100H' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-dr250b'} productImg={AirBarDR250BImg} productTitle='AirBar®'  productSubtitle='DR250B' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-dr250v'} productImg={AirBarDR250VImg} productTitle='AirBar®'  productSubtitle='DR250V' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-dr250h'} productImg={AirBarDR250HImg} productTitle='AirBar®'  productSubtitle='DR250H' productCategory='productCategories-UVC'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default HomeHotelClinicGym;