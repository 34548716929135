import React from "react";
import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import T8Img from '../graphics/products/UVA_tubes.jpg';
import DRF from '../graphics/products/T8-10W-DRF.png';

import ProductBox from "../components_en/ProductBox";


function UVTubes () {
    return(
        <>
        <Header/>
        <TitleSection h1={'UVA LED Tubes'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
        <span className="productCategories-productGroupDescription">
        This is an LED version of a standard fluorescent tube, emitting UVA radiation and offering a significantly longer lifespan. It's energy-efficient and features a special material that allows the passage of ultraviolet radiation. This lamp is primarily designed for use in insect traps. The standard length is 450mm, but it can be custom-made in different lengths and power ratings according to customer requirements.
        </span>
            <div id='productCategories-productsGrid'>
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-9w'} productImg={T8Img} productTitle='T8 9W' productCategory='productCategories-UVA'/>
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-10w'} productImg={T8Img} productTitle='T8 10W' productCategory='productCategories-UVA'/>
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-13w'} productImg={T8Img} productTitle='T8 13W' productCategory='productCategories-UVA'/>  
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-9w-flywings-simulation'} productImg={T8Img} productTitle='T8-9W FlyWings Simulation' productCategory='productCategories-UVA'/> 
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-9w-dfr'} productImg={DRF} productTitle='T8 9W DFR  (Direct Fluorescent Replacement)' productCategory='productCategories-UVA'/>               
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default UVTubes;