import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import AgriJet4000Img from '../graphics/products/AgriJet4000.png';
import UVCChamberSImg from '../graphics/products/UVC-Chamber-S.jpg';

import ProductBox from "../components_en/ProductBox";


function AgricultureAndIndustry () {
    return(
        <>
        <Header/>
        <TitleSection h1={'Rolnictwo i przemysł'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <h4 style={{color: "#565757"}}>Urządzenia do oczyszczania powietrza UVC dedykowane dla przemysłu i rolnictwa.</h4>
            <div id='productCategories-productsGrid'>
            <ProductBox productPageHref={'/products/animal-centric-lighting/agri-jet-4000'} productImg={AgriJet4000Img} productTitle='AgriJet 4000®' productSubtitle='' productCategory='productCategories-Agri' productCategoryText='Dezynfekcja powietrza'/>
            <ProductBox productPageHref={'/products/animal-centric-lighting/uvc-chamber-s'} productImg={UVCChamberSImg} productTitle='UVC Chamber S' productCategory='productCategories-Agri' productCategoryText='Dezynfekcja powierzchni'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default AgricultureAndIndustry;