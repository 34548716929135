import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/App.css';
import '../styles/Contact.css';

function PrivacyPolicy () {
        return(
            <>
                <Header />
                <TitleSection h1={'PRIVACY POLICY'} h2={''} height='280px'/>
                <div id='privacyPolicy-secondSection'>
                    <div className='privacyPolicy-centeredBox'>
                        <ol>
                            <li className='privacyPolicy-list'> 
                                <span className='priacyPolicy-bold'>What is the legal basis for the processing of personal data?</span>
                                The legal basis is Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46 / EC (general regulation on data protection) - GDPR.
                            </li>
                            <li className='privacyPolicy-list'>
                                <span className='priacyPolicy-bold'>Where do we get the data from?</span>
                                We obtain data by phone, e-mail after you have sent a message directly by e-mail or from the contact forms on the website www.nextlighting.eu.
                            </li>
                            <li className='privacyPolicy-list'>
                                <span className='priacyPolicy-bold'>What data do we process and for what purpose?</span>
                                We process the necessary data required to: contact, implement and archive the services provided, sign contracts and issue invoices. We make every effort to ensure that your data is safe with us.
                            </li>
                            <li className='privacyPolicy-list'>
                                <span className='priacyPolicy-bold'>What data and for what purpose do we entrust for processing?</span>
                                Data entrusted for processing to third parties are only provided when the performance of the ordered service requires it.
                                Transfer of personal data.
                                You have the right to transfer your data (all or selected) that is processed by us.
                            </li>
                            <li className='privacyPolicy-list'>
                                <span className='priacyPolicy-bold'>How long do we store and process data?</span>
                                We will store and process the data provided to us indefinitely, until you withdraw your consent to their storage and processing.
                                By providing data, you have the right to: view, change or delete from the database. It is possible to withdraw consent to data processing by sending an appropriate message to sales@nextlighting.eu.
                            </li>
                            <li className='privacyPolicy-list'>
                                <span className='priacyPolicy-bold'>Right to lodge a complaint.</span>
                            You can submit complaints regarding the processing of your personal data to the Inspector General for Personal Data Protection (GIODO).
                            </li>
                            <li className='privacyPolicy-list'> 
                                <span className='priacyPolicy-bold'>Who is the administrator of personal data?</span>
                                The data administrator is NEXT Lighting Ltd, Chrzanowskiego 23 str., 87-100 Toruń, Poland, VAT ID: PL8792733632, telephone: +48 789 036 056, e-mail: sales@nextlighting.eu.
                            </li>
                        </ol>
                    </div>
                </div>
                <Footer />
            </>
        );
}

export default PrivacyPolicy;