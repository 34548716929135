import React from "react";
import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import skyPanelImg from '../graphics/products/skyPanel.jpg';
import longProHumanImg from '../graphics/products/longProHuman.jpg';
import ProductBox from "../components_en/ProductBox";


function HumanCentricLighting () {
    return(
        <>
        <Header/>
        <TitleSection h1={'HUMAN CENTRIC LIGHTING'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <div className='productCategories-textParagraphRowBox'>
                <p className='productCategories-textParagraph'>
                Human centric lighting  (HCL) is a concept of interior lighting focused on humans, taking into account not only visual but also emotional and non-visual effects of light. HCL typically involves adapting lighting to the natural progression of daylight. In addition to the intensity of lighting, the change in light color temperature is essential. In the evening, the lighting is reduced. In the case of HCL, the light's color spectrum should change throughout the day, just as in natural light: higher levels of blue in the morning, warmer light at sunrise, an increase in blue components at noon, a decrease in blue components towards the evening, and higher blue components with low light after sunset (the blue hour). The changing light acts through photosensitive ganglion cells in the eye containing melanopsin to directly synchronize the biological clock and release the sleep hormone melatonin from the pineal gland. [1]
                </p>
            </div>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/human-centric-lighting/sky-panel'} productImg={skyPanelImg} productTitle='Sky panel' productCategory='productCategories-Human'/>
                <ProductBox productPageHref={'/products/human-centric-lighting/longpro-hcl'} productImg={longProHumanImg} productTitle='LongPro HCL' productCategory='productCategories-Human'/>
            </div>
            <p style={{fontSize: "12px", width: "900px", marginBottom: "20px", fontSize: "12px"}}>[1] Lichtmanagement als Zukunftstechnologie: Der fünfte Sinn des Auges und wie wir ihn nutzen können. vom Fraunhofer-Institut für Arbeitswirtschaft und Organisation IAO, abgerufen am 2. Februar 2020"</p>
        </div>
        <Footer/>
        </>
    );
}

export default HumanCentricLighting;