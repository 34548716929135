import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import noiseIconImg from '../../graphics/icons/noiseIcon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import RemoteControllerIconImg from '../../graphics/icons/RemoteControllerIcon.png';
import filterIconImg from '../../graphics/icons/filterIcon.jpg';
import UVC_IconImg from  '../../graphics/icons/UVC_icon.jpg';
import MinusIconImg from  '../../graphics/icons/minusIcon.png';
import AirBar40TransportImg from '../../graphics/products/AirBar40Transport.jpg';
import Pic1Img from '../../graphics/products/AirBarTransport/Pic1.jpg';
import Pic2Img from '../../graphics/products/AirBarTransport/Pic2.jpg';
import Pic3Img from '../../graphics/products/AirBarTransport/Pic3.jpg';

import ProductSlider from "../../components_en/ProductSlider";
import AirBar40TransportTechnicImg from '../../graphics/products/AirBar40TransportTechnic.png';


function AirBar40Transport () {
    return(
        <>
            <Header/>
            <TitleSection h1={'AirBar® SR40 Transport'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
                        <div className='productCategories-productPageContainer'>
                        <div className='productCategories-airPaintMount'>
                                <div className='productCategories-pictureWithDescription'>
                                    <img src={Pic1Img}/>
                                    <h4>Elevators</h4>
                                </div>
                                <div className='productCategories-pictureWithDescription'>
                                    <img src={Pic2Img}/>
                                    <h4>Bus & Train</h4>
                                </div>
                                <div className='productCategories-pictureWithDescription'>
                                    <img src={Pic3Img}/>
                                    <h4>Ambulances</h4>
                                </div>
                        </div>
                        <span style={{width: "80%"}} className='productCategories-productGroupDescription'>
                        AirBar40 is an unique device that uses UV-C rays to disinfect all means of transport - passenger cars and delivery vans, trams, trains and elevators. Its task is to clean the flowing air of pathogens, toxic substances, viruses, bacteria, molds and gases with an "unpleasant smell". It consists of PCO and UV-C modules that reduce pollution by 99.99%. This is particularly important in medical and veterinary transport, public transport and the transport of agri-food products. The device can be mounted vertically or horizontally and is designed to disinfect air with a capacity of approximately 40 m3/h.
                </span>
                        <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                                <ul className="productCategories-secondSectionList">
                                    <li>Kills viruses, bacteria and mould</li> 
                                    <li>Reduces unpleasant odours</li>
                                    <li>Purification Engine based on UV LEDs</li>
                                    <li>No glass, no mercury, shock and vibration resistance</li>
                                    <li>Efficiency 40 m3 /h, about 5 air exchanges per hour in ambulance</li>
                                    <li>Purification chamber (reactor) made out of UVC highly reflective material</li>
                                    <li>Limited maintenance (Lifetime of the UV-C module up to 50.000 hours)</li>
                                    <li>PCO module (Photocatalysis Oxidation) life time up to 50.000 hrs</li>
                                    <li>Unit equipped with microprocessor controller with advanced control of
                                    purification modules working time, temperature and flow level</li>
                                    <li>LED working mode signaling</li>
                                    <li>No Ozone produced</li>
                                </ul>
                        </div>
                        <ProductSlider productImages={[AirBar40TransportImg, AirBar40TransportTechnicImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Dimensions:</p>
                                <p>Mounting: / Front panel</p>
                                <p>H: 600mm / 621mm</p>
                                <p>W: 180mm / 165mm</p>
                                <p>D: 56mm / 59mm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Weight: 2.5kg</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Voltage: 12-19V DC</p>
                                <p>Power: 60W</p>
                                <p>IP20 (IEC 60529)</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={noiseIconImg} alt='icon'/>
                                <p>Noise level: {'<'} 39dB </p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <p>Air flow: up to 40m3/h</p>
                                <p>UVC SOURCE: UVC LED Module 50khrs</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={RemoteControllerIconImg} alt='icon'/>
                                <p>MCU Controller: Lifetime, efficiency, air flow</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Disinfection technologies:</p>
                                <ul>
                                    <li>PP filter or HEPA filter (option)</li>
                                    <li>UVC disinfection</li>
                                    <li>PCO disinfection</li>
                                </ul>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={filterIconImg} alt='icon'/>
                                <img className='productParametersIcon' src={UVC_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={MinusIconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                    </div>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default AirBar40Transport;