import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import ProductBox from "../components_en/ProductBox";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';
import UVATubesImg from '../graphics/products/UVA_tubes.jpg';
import InsectTrapImg from '../graphics/products/InsectTrap.jpg';

function UVAInsectTraps () {
    return(
        <>
        <Header/>
        <TitleSection h1={'Muchołapki UVA'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes'} productImg={UVATubesImg} productTitle='Lampy trzonkowe UVA' productCategory='productCategories-UVA'/>
                <ProductBox productPageHref={'/products/uva-insect-traps/insect-trap'} productImg={InsectTrapImg} productTitle='Insect Trap' productCategory='productCategories-UVA'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default UVAInsectTraps;