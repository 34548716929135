import React from "react";
import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import longProWhiteImg from '../graphics/products/long-pro-white.jpg';
import xaraE27BulbImg from '../graphics/products/xaraE27bulb.jpg';
import sunProImg from '../graphics/products/sun-pro.jpg';
import pro15Img from '../graphics/products/pro15.png';
import proBoxImg from '../graphics/products/proBox.jpg';
import AgriJet4000Img from '../graphics/products/AgriJet4000.png';
import UVCChamberSImg from '../graphics/products/UVC-Chamber-S.jpg';
import plumImg from '../graphics/products/plum-48v-white.jpg';
import ProductBox from "../components_en/ProductBox";


function AnimalCentricLighting () {
    return(
        <>
        <Header/>
        <TitleSection h1={'ANIMAL CENTRIC LIGHTING'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <div className='productCategories-textParagraphRowBox'>
                <p className='productCategories-textParagraph'>
                Animals are also sensitive to the circadian rhythm and the color
                and intensity of light. Our devices help them feel like in the nature.
                Only in Poland we produce over 9 billion eggs a year. Our products
                allow you to reduce the so-called. contaminated laying eggs by
                eliminating shading in undesirable places.​
                </p>
                <p className='productCategories-textParagraph'>
                We are engineer designers and producers of advanced devices used to control single or double
                channel lamps. They have built-in 0-10V, RS485, Bluetooth and USB interfaces. We design,
                construct and produce LED lamps with a water resistance class of up to IP69 with a wide
                beam angle with advanced electronics that allow you to adjust the brightness in the range
                of 0-100%, two-channel, each channel is regulated separately, we mix colors. During the
                construction of the lamps, we have often cooperated with the Warsaw University of
                Technology, which developed a llama profile with a sufficiently wide light distribution.​
                </p>
            </div>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/animal-centric-lighting/longpro-products'} productImg={longProWhiteImg} productTitle='LongPro' productCategory='productCategories-Agri' productCategoryText='Linear tubes'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products'} productImg={plumImg} productTitle='Plum' productCategory='productCategories-Agri' productCategoryText='360° Lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/xara'} productImg={xaraE27BulbImg} productTitle='Xara E27 Bulb' productCategory='productCategories-Agri' productId='productCategories-xaraMiniature' productCategoryText='LED bulbs'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/sunpro'} productImg={sunProImg} productTitle='SunPro' productCategory='productCategories-Agri' productCategoryText='Sunlight simulator (SLS)'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/pro15'} productImg={pro15Img} productTitle='Pro15' productCategory='productCategories-Agri' productCategoryText='Dimmer'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/pro15-plus'} productImg={pro15Img} productTitle='Pro15 plus' productCategory='productCategories-Agri' productCategoryText='Dimmer with SLS'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/probox'} productImg={proBoxImg} productTitle='ProBox' productCategory='productCategories-Agri' productCategoryText='Box dimmer with SLS'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/uvc-chamber-s'} productImg={UVCChamberSImg} productTitle='UVC Chamber S' productCategory='productCategories-Agri' productCategoryText='Surface disinfection'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/agri-jet-4000'} productId='products-agrijetMiniature' productImg={AgriJet4000Img} productTitle='AgriJet®4000' productCategory='productCategories-Agri' productCategoryText='Air disinfection'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default AnimalCentricLighting;