import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import AirBarSR100VImg from '../graphics/products/AirBar100V.jpg';
import AirBarDR250BImg from '../graphics/products/DR250B.jpg';
import AirBarDR250HImg from '../graphics/products/AirBarSR100H.jpg';
import AirBarTransportImg from '../graphics/products/AirBar40Transport.jpg';
import AirPaintImg from '../graphics/products/AirPaint40.jpg';

import AgriJet4000Img from "../graphics/products/AgriJet4000.png";
import UVCChamberSImg from "../graphics/products/UVC-Chamber-S.jpg";

function UVCAirDisinfection () {
    return(
        <>
        <Header/>
        <TitleSection h1={'UVC AIR DISINFECTION'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <div className='productCategories-textParagraphRowBox'>
                <p className='productCategories-textParagraph'>
                Naszą misją podczas tworzenia urządzeń do sterylizacji i oczyszczania powietrza było skonstruowanie takich urządzeń, które pozwolą naszym klientom cieszyć się doskonale czystym powietrzem, jak w wysokich górach, bez bakterii, wirusów, pleśni czy alergenów. Ponadto, powietrze bez nieprzyjemnych zapachów i lekko jonizowane, takie jak w lasach iglastych czy przy wodospadach górskich.
                In addition, air without unpleasant odours and slightly ionized as in a coniferous forest or at mountain waterfalls.
                </p>
                <p className='productCategories-textParagraph'>
                Udało nam się stworzyć najlepsze w swojej klasie sterylizatory powietrza, wykorzystując promieniowanie UV, jak na szczytach gór, dokładnie taką samą jonizację opartą na wodzie i tlenu, jak przy wodospadzie, czy w lesie po deszczu, w połączeniu z filtrami PP/HEPA, usuwającymi alergeny z kurzu. Najwyższą wydajność i jakość naszych urządzeń potwierdziły międzynarodowe certyfikaty.
                </p>
            </div>
            <div id="productCategories-productCategoriesBigBox">
                <a href="/products/uvc-air-desinfection/airbar-40-transport">
                <div className="productCategories-uvaTile productCategories-UVC">
                        <div className="productCategories-productCategoriesBigBoxTop">
                            <img style={{height: '250px'}} src={AirBarTransportImg} />
                        </div>
                        <div className="productCategories-productCategoriesBigBoxBottom">
                            <div className='productCategories-productGroupName'><span className='productCategories-productTitle'>Autobusy, tramwaje i pociągi</span></div>
                        </div>
                </div>
                </a>
                <a href="/products/uvc-air-desinfection/agriculture-and-industry">
                <div className="productCategories-uvaTile productCategories-UVC">
                        <div className="productCategories-productCategoriesBigBoxTop">
                            <img style={{height: '120px'}} src={AgriJet4000Img} />
                            <img style={{height: '150px'}} src={UVCChamberSImg} />
                        </div>
                        <div className="productCategories-productCategoriesBigBoxBottom">
                        <div className='productCategories-productGroupName'><span className='productCategories-productTitle'>Rolnictwo i przemysł</span></div>
                        </div>
                    </div>
                </a>
                </div>
                <a href="/products/uvc-air-desinfection/home-hotel-clinic-gym">
                <div className="productCategories-uvaTile productCategories-UVC">
                        <div className="productCategories-productCategoriesBigBoxTop">
                            <img style={{height: '125px'}} src={AirBarSR100VImg} />  
                            <img style={{height: '120px'}} src={AirBarDR250BImg} />
                            <img style={{height: '150px'}} src={AirPaintImg} />
                            <img style={{height: '120px'}} src={AirBarDR250HImg} />  
                        </div>
                        <div className="productCategories-productCategoriesBigBoxBottom">
                        <div className='productCategories-productGroupName'><span className='productCategories-productTitle'>Domy/Hotele/Siłownie/Przychodnie</span></div>
                        </div>
                </div>
                </a>
        </div>
        <Footer/>
        </>
    );
}

export default UVCAirDisinfection;