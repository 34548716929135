import React, { useEffect, useState } from 'react';

class ProductSlider extends React.Component {
    constructor (props)
    {
        super(props);
        this.imgVal = 0;
        this.imgArrayLength = this.props.productImages.length;
    }
    componentDidMount() {  
        const sliderImg = document.getElementById("productSlider-img");
        sliderImg.src = this.props.productImages[0];
    }
    render () {
        const changeImg = (i) => {
            const sliderImg = document.getElementById("productSlider-img");
            if ((this.imgVal + i >= 0) && (this.imgVal + i < this.imgArrayLength))
                this.imgVal += i;
            else
            {
                if (this.imgVal + i < 0)
                {
                    this.imgVal = this.imgArrayLength - 1;
                }
                if (this.imgVal + i >= this.imgArrayLength)
                {
                    this.imgVal = 0;
                }
            }
            sliderImg.src = this.props.productImages[this.imgVal];
        }
        return (
            <div className='productSlider'>
                <button className='sliderController' onClick={() => {changeImg(-1)}}>&#60;</button>
                <div className='productSliderBox'>
                    <img id='productSlider-img' />
                </div>
                <button className='sliderController' onClick={() => {changeImg(1)}}>&#x3e;</button>
            </div>
        );
    }
}

export default ProductSlider;