import React from "react";
import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import ProductBox from "../components_en/ProductBox";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import plum48VWhiteImg from '../graphics/products/plum-48v-white.jpg';
import plum230VWhiteImg from '../graphics/products/plum-230v-white.jpg';

function Plum () {
    return(
        <>
        <Header/>
        <TitleSection h1={'PLUM'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <span className="productCategories-productGroupDescription">
            An innovative LED lamp with the most spectrum of applications, suitable for virtually any animal husbandry. This lamp can be mount on any surface: ceiling, wall, fixtures, and cages. Plum combines a standard LED bulb with a lighting fixture, significantly reducing costs in planned investments. The use of external ballasts greatly shortens the installation time. It's worth noting that no screws are used in the Plum lamp and the assembly is done with a ,,click mechanism".
            </span>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-48v-white'} productImg={plum48VWhiteImg} productTitle='Plum 48V' productSubtitle='White' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-48v-white-blue'} productImg={plum48VWhiteImg} productTitle='Plum 48V' productSubtitle='White + Blue' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-48v-white-red'} productImg={plum48VWhiteImg} productTitle='Plum 48V' productSubtitle='White + Red' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-110v-white'} productImg={plum230VWhiteImg} productTitle='Plum 110V' productSubtitle='White' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-230v-white-blue'} productImg={plum230VWhiteImg} productTitle='Plum 230V' productSubtitle='White + Blue' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-230v-white-red'} productImg={plum230VWhiteImg} productTitle='Plum 230V' productSubtitle='Digital White + Red' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-230v-white'} productImg={plum230VWhiteImg} productTitle='Plum 230V' productSubtitle='White' productCategory='productCategories-lamps'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default Plum;