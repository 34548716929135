import React from "react";

class ProductBox extends React.Component {
    render () {
        let classNameTemp = 'productCategories-productBox' + ' ' + this.props.productCategory;
        return(
            <a href={this.props.productPageHref}>
             <div className={classNameTemp}>
                <p className='productCategories-productTitle'>{this.props.productCategoryText}</p>
                <div className='productCategories-productBoxImgContainer'>
                    <img id={this.props.productId} className='productCategories-productMiniature' src={this.props.productImg} alt={this.props.productTitle}/>
                </div>
                <div className='productCategories-productName'>
                    <span className='productCategories-productTitle'>{this.props.productTitle}</span>
                    <span className='productCategories-productTitle'>{this.props.productSubtitle}</span>
                </div>
            </div>
            </a>
        );
    }
}

export default ProductBox;