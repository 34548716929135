import React from "react";
import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import AgriJet4000Img from '../graphics/products/AgriJet4000.png';
import UVCChamberSImg from '../graphics/products/UVC-Chamber-S.jpg';

import ProductBox from "../components_en/ProductBox";


function AgricultureAndIndustry () {
    return(
        <>
        <Header/>
        <TitleSection h1={'Agriculture and industry'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <h4 style={{color: "#565757"}}>UVC air purification devices created especially for industry and agri-culture.</h4>
            <div id='productCategories-productsGrid'>
            <ProductBox productPageHref={'/products/animal-centric-lighting/agri-jet-4000'} productImg={AgriJet4000Img} productTitle='AgriJet 4000®' productSubtitle='' productCategory='productCategories-Agri' productCategoryText='Air disinfection'/>
            <ProductBox productPageHref={'/products/animal-centric-lighting/uvc-chamber-s'} productImg={UVCChamberSImg} productTitle='UVC Chamber S' productCategory='productCategories-Agri' productCategoryText='Surface disinfection'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default AgricultureAndIndustry;