import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/App.css';
import '../styles/Odm.css';
import '../styles/Competences.css';

import icon1 from "../graphics/icons/icon-01-start.png";
import icon2 from "../graphics/icons/icon-02-next.png";
import icon3 from "../graphics/icons/icon-03-next.png";
import icon4 from "../graphics/icons/icon-04-next.png";
import icon5 from "../graphics/icons/icon-05-next.png";
import icon6 from "../graphics/icons/icon-06-next.png";

import competences01Img from "../graphics/competences-01.jpg";
import competences02Img from "../graphics/competences-02.jpg";

function Competences () {
        return(
            <>
                <Header />
                    <TitleSection h1={'OUR COMPETENCES'} h2={''} height='280px'/>
                    <div id='competences-secondSection'>
                            <div id='competences-secondSectionCenteredBox'>
                                <div className='competences-cards'>
                                    <img className='competences-img' src={competences01Img} alt="Image"></img> 
                                    <span className='competences-text'>
                                    Our R&D team has developed the concepts of many lighting and UV products. 
                                    We have experts in the field of  <span className='boldText'>microcontrollers, programmers, power electronics, optics, metals, mechanics</span>, as well as measurements and <span className='boldText'>validation</span>. The <span className='boldText'>DFSS</span> Black Belt watches over the quality of research and development. 
                                    </span>
                                </div>
                                <div className='competences-cards'>
                                    <img className='competences-img' src={competences02Img} alt="Image"></img> 
                                    <span className='competences-text'>
                                    Experience in industrialization using tools such as <span className='boldText'>Design For Six Sigma, DFMA or QFD. ESD</span> regime in the entire production hall. Over 20 years of experience in industral engineering and <span className='boldText'>LEAN</span> manufacturing using <span className='boldText'>VSM, Yamazumi, SMED, One Piece Flow or Cell Concept.</span> Almost 30 years x-region supply chain experience. 
                                    </span>
                                </div>
                            </div>
                    </div>
                    <div id='competences-thirdSection'>
                        <div className='competences-thirdSectionTableBox'>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Concept design</span>
                                <img className='competences-thirdSectionTableIcon' src={icon1} alt='Concept design'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Development</span>
                                <img className='competences-thirdSectionTableIcon' src={icon2} alt='Development'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Prototyping</span>
                                <img className='competences-thirdSectionTableIcon' src={icon3} alt='Prototyping'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Industrialization</span>
                                <img className='competences-thirdSectionTableIcon' src={icon4} alt='Industrialization'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Production </span>
                                <img className='competences-thirdSectionTableIcon' src={icon5} alt='Production'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Logistics</span>
                                <img className='competences-thirdSectionTableIcon' src={icon6} alt='Logistics'/>
                            </div>   
                    </div>
                    </div>
                <Footer />
            </>
        );
}

export default Competences;