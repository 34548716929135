import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import proBoxImg from "../../graphics/products/proBox.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import ProductSlider from "../../components_en/ProductSlider";


function ProBox () {
    return(
        <>
            <Header/>
            <TitleSection h1={'ProBox'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                <span className='productCategories-productDescription'>
                        Probox is a dimmer and controller in one, equipped with a graphical LCD display that provides information about its status, security features, and functions. Power adjustment is achieved through phase-cutting, known as trailing edge. It is designed to handle resistive and capacitive loads. The controller allows for manual and automatic lighting control by setting a daily lighting control curve. The dimmer supports dimmable light sources such as incandescent bulbs, 230V AC halogen bulbs, low-voltage halogen bulbs powered by electronic transformers, dimmable CFLs, cold cathode fluorescent lamps (CCFL), and LED bulbs.
                        Its maximum load capacity is 1000W. It has built-in electronic protections against overload, short circuits, overheating, and network surges.
                        <br></br><br></br>
                        The controller features software that allows for advanced simulation of sunrise and sunset, setting any brightness level at any time. It also has a built-in 24/7 clock with a full calendar and memory backup. Equipped with three outputs for controlling external devices:
                        <ul>
                            <li>output for controlling an alarm system or alarm siren.</li>
                            <li>output for controlling an external relay, e.g., for galvanic lighting disconnection.</li>
                            <li>0-10V output for controlling additional dimmers or other devices. The voltage on this output is proportional to the set lighting intensity. </li>
                        </ul>
                </span>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Combination of selected dimmer functions with the controller</li>
                                <li>1000W dimmer built-in</li>
                                <li>Controller to simulate sunrises and sunsets</li>
                                <li>The ability to control external lighting regulators using the 1-10V interface</li>
                                <li>Build -in Full electronic protections</li>
                                <li>Output for controlling an alarm system</li>
                                <li>Output for external relay</li>
                                <li>Full calendar 7/24</li>
                                <li>Possibility to set 16 levels per day</li>
                                <li>High degree of dust and water resistance</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[proBoxImg]}/>
                </div>
                <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Dimensions (whole system approx.):</p>
                            <p>H: 200mm</p>
                            <p>W: 150mm</p>
                            <p>D: 85mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Weight: 700g</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Voltage: 100-230V 50/60Hz</p>
                            <p>Load Power: 1000VA max</p>
                            <p>IP56 (IEC 60529)</p>
                            <p>Mounting: on a wall</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Build-in Protections:</p>
                            <p>- Over-Load</p>
                            <p>- Short-circuit</p>
                            <p>- Over-temperature</p>
                            <p>- Over-voltage</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Standards:</p>
                            <p>Safety EN 60598-1, EN 62471</p>
                            <p>EMC EN 55022</p>
                            <p>The Dimmer is safe to use under conditions -10 - 45˚</p>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default ProBox;