import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import UVATubeImg from '../../graphics/products/UVA_tubes.jpg';
import DRF_Icon from '../../graphics/products/T8-10W-DRF.png';
import ProductSlider from '../../components_en/ProductSlider'


function T89WDRF () {
    return(
        <>
            <Header/>
            <TitleSection h1={'T8 9W DFR  (Direct   Fluorescent Replacement)'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <div className='productCategories-descriptionAndParameters'>
                    <span className='productCategories-productDescription'>
                    This is a unique version of the T8 LED lamp that can replace a standard fluorescent lamp in single and double luminaires with a magnetic ballast.
                    No modification to the standard fixture is necessary to replace the lamp. 
                    The box contains a T8 LED lamp and an additional "LED starter" that needs to be replaced in the fixture. 
                    In addition to a single luminaire, the lamp has the unique ability to work in a double luminaire in which fluorescent lamps were connected in series. Then, half of the voltage is present on each lamp and the T8 lamps continue to operate with the same rated power. The lamp driver specially designed by our R&D department ensures stable operation and even power distribution of both lamps.
                    </span>
                        <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                            <li>Designed and manufactured in EU</li>
                            <li>The best quality diodes from Korea</li>
                            <li>Durability of 20,000 hours</li>
                            <li>High effectiveness in catching flies confirmed by tests</li>
                            <li>100% of construction and production in Poland</li>
                            <li>Homogeneous light, no spottines (optics from Germany)</li>
                            <li>Attractive price</li>
                            <li>LED starter included in the box</li>
                            <li>Warranty 2 years</li>
                            </ul>
                        </div>
                        <ProductSlider productImages={[DRF_Icon]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                                <p>Dimensions:</p>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Diameter 25.8mm</p>
                                <p>L: 450mm</p>                                          
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Voltage: 230V</p>
                                <p>Power: 9W</p>
                                <p>Current: 50mA</p>
                                <p>IP20 (IEC 60529) </p>
                                <p>UVA: 3.7W</p>
                                <p>UVA LED: 370.5 nm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Operating temperature: -40C to 45C</p>
                                <p>Storage temperature: -45C to 60C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                        </div>
        </div>
        <Footer/>
        </>
    );
}

export default T89WDRF;