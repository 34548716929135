import logoWhite from '../graphics/logo-next-lighting-white.png';
import logoDark from '../graphics/logo-next-lighting-dark.png';
import { useEffect, useRef } from 'react';
import pl_flag from '../graphics/pl_flag.webp';
import en_flag from '../graphics/en_flag.webp';

function Header() {
  const setLanguage = (language) => {
    localStorage.setItem('language', language);
    window.location.reload();
  }
  let headerType = 'header';
  const ref = useRef(null);
  const showOrHideMenu = () => {
    document.getElementById("menu").classList.toggle('fixed');
    document.getElementById("mobile-menu-button").classList.toggle('fixed');
  }
  useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.scrollY > ref.current.clientHeight + 50)
        {
          if (headerType == "header")
          {
            headerType = "headerFixed";
            const header = document.getElementById("header");
            header.classList.toggle("fixed");
            document.getElementById("mainHeaderLogo").src = logoDark;
            document.getElementById("mainHeaderLogo").classList.toggle("fixed");
            let menuItems = document.getElementsByClassName("menuItem");
            let menuButtonLine = document.getElementsByClassName("menuButtonLine");
            for (let i = 0; i < menuItems.length; i++)
            {
              menuItems[i].classList.toggle("fixed");
            }
            for (let i = 0; i < menuButtonLine.length; i++)
            {
              menuButtonLine[i].classList.toggle("fixed");
            }
          }
        }
        else
        {
          if (headerType == "headerFixed")
          {
            headerType = "header";
            const header = document.getElementById("header");
            header.classList.toggle("fixed");
            document.getElementById("mainHeaderLogo").src = logoWhite;
            document.getElementById("mainHeaderLogo").classList.toggle("fixed");
            let menuItems = document.getElementsByClassName("menuItem");
            let menuButtonLine = document.getElementsByClassName("menuButtonLine");
            for (let i = 0; i < menuItems.length; i++)
            {
              menuItems[i].classList.toggle("fixed");
            }
            for (let i = 0; i < menuButtonLine.length; i++)
            {
              menuButtonLine[i].classList.toggle("fixed");
            }
          } 
        }
      });
  });
  return (
    <div id="header" ref={ref}>
        <div id='headerCentered'>
            <div id='headerLogoBox'>
              <a href="/"><img id={'mainHeaderLogo'} src={logoWhite} alt='Next lighting'/></a>
              <div id='mobile-menu-button' onClick={() => showOrHideMenu()}>
                <div className={"menuButtonLine line"}></div>
                <div className={"menuButtonLine line"}></div>
                <div className={"menuButtonLine line"}></div>
              </div>
            </div>
            <ul id='menu'>
              <li><a className={'menuItem'} href='/'>COMPANY<br></br>INTRO</a></li>
              <li><a className={'menuItem'} href='/services'>SERVICES<br></br>ODM / OEM</a></li>
              <li><a className={'menuItem'} href='/products'>PRODUCTS<br></br>ON THE SHELF</a></li>
              <li><a className={'menuItem'} href='/our-competences'>OUR<br></br>COMPETENCES</a></li>
              <li><a className={'menuItem'} href='/contact'>CONTACT<br></br>US</a></li>
            </ul>
            <div id="headerLanguageBox">
              <div className="flagBox" onClick={() => setLanguage('en')}>
                <img className='headerFlag' src={en_flag} alt='en'/>
              </div>
              <div className="flagBox" onClick={() => setLanguage('pl')}>
                <img className='headerFlag' src={pl_flag} alt='pl'/>
              </div>
            </div>
        </div>
    </div>
  );
}

export default Header;
