import React from "react";
import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import AirBarSR100VImg from '../graphics/products/AirBar100V.jpg';
import AirBarDR250BImg from '../graphics/products/DR250B.jpg';
import AirBarDR250HImg from '../graphics/products/AirBarSR100H.jpg';
import AirBarTransportImg from '../graphics/products/AirBar40Transport.jpg';
import AirPaintImg from '../graphics/products/AirPaint40.jpg';

import AgriJet4000Img from "../graphics/products/AgriJet4000.png";
import UVCChamberSImg from "../graphics/products/UVC-Chamber-S.jpg";

function UVCAirDisinfection () {
    return(
        <>
        <Header/>
        <TitleSection h1={'UVC AIR DISINFECTION'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <div className='productCategories-textParagraphRowBox'>
                <p className='productCategories-textParagraph'>
                Our mission when creating devices for sterilization and air purification was to construct such devices that will allow our customers to enjoy perfectly clean air, like in the high mountains, without bacteria, viruses, mould or allergens. 
                In addition, air without unpleasant odours and slightly ionized as in a coniferous forest or at mountain waterfalls.
                </p>
                <p className='productCategories-textParagraph'>
                We have managed to create the best in class air sterilizers using UV radiation, as on the mountain tops, exactly the same ionization based on water and oxygen, as at the waterfall, or in the forest after the rain, together with PP / HEPA filters that remove allergens from dust. The highest efficiency and quality of our devices has been confirmed by international certificates. 
                </p>
            </div>
            <div id="productCategories-productCategoriesBigBox">
                <a href="/products/uvc-air-desinfection/airbar-40-transport">
                <div className="productCategories-uvaTile productCategories-UVC">
                        <div className="productCategories-productCategoriesBigBoxTop">
                            <img style={{height: '250px'}} src={AirBarTransportImg} />
                        </div>
                        <div className="productCategories-productCategoriesBigBoxBottom">
                            <div className='productCategories-productGroupName'><span className='productCategories-productTitle'>Transportation and elevators</span></div>
                        </div>
                </div>
                </a>
                <a href="/products/uvc-air-desinfection/agriculture-and-industry">
                <div className="productCategories-uvaTile productCategories-UVC">
                        <div className="productCategories-productCategoriesBigBoxTop">
                            <img style={{height: '120px'}} src={AgriJet4000Img} />
                            <img style={{height: '150px'}} src={UVCChamberSImg} />
                        </div>
                        <div className="productCategories-productCategoriesBigBoxBottom">
                        <div className='productCategories-productGroupName'><span className='productCategories-productTitle'>Agriculture and  industry</span></div>
                        </div>
                    </div>
                </a>
                </div>
                <a href="/products/uvc-air-desinfection/home-hotel-clinic-gym">
                <div className="productCategories-uvaTile productCategories-UVC">
                        <div className="productCategories-productCategoriesBigBoxTop">
                            <img style={{height: '125px'}} src={AirBarSR100VImg} />  
                            <img style={{height: '120px'}} src={AirBarDR250BImg} />
                            <img style={{height: '150px'}} src={AirPaintImg} />
                            <img style={{height: '120px'}} src={AirBarDR250HImg} />  
                        </div>
                        <div className="productCategories-productCategoriesBigBoxBottom">
                        <div className='productCategories-productGroupName'><span className='productCategories-productTitle'>Home/Hotel/Clinic/Gym</span></div>
                        </div>
                </div>
                </a>
        </div>
        <Footer/>
        </>
    );
}

export default UVCAirDisinfection;