import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import noiseIconImg from '../../graphics/icons/noiseIcon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import filterIconImg from '../../graphics/icons/filterIcon.jpg';
import UVC_IconImg from  '../../graphics/icons/UVC_icon.jpg';
import MinusIconImg from  '../../graphics/icons/minusIcon.png';
import AirBarSR100HImg from '../../graphics/products/AirBarSR100H.jpg';

import ProductSlider from "../../components_en/ProductSlider";

import Img1Img from '../../graphics/products/ProductsImgs/Img1.jpg';
import Img2Img from '../../graphics/products/ProductsImgs/Img2.jpg';
import Img3Img from '../../graphics/products/ProductsImgs/Img3.jpg';


function AirBarSR100H () {
    return(
        <>
            <Header/>
            <TitleSection h1={'AirBar® SR100H'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
                <div className='productCategories-productPageContainer'>
                <div className='productCategories-productPageContainer'>
                <div className="productCategories-productCategoriesBigBoxTop">
                    <img style={{height: "200px", marginTop: "20px"}} src={Img1Img} />
                    <img style={{height: "200px", marginTop: "20px"}} src={Img2Img} />
                    <img style={{height: "200px", marginTop: "20px"}} src={Img3Img} />
                </div>
                    <div className='productCategories-descriptionAndParameters'>
                            <ul className="productCategories-secondSectionList">
                                <li>Kills viruses, bacteria and mould</li> 
                                <li> Reduces unpleasant odours</li>
                                <li> Improves well-being through negative air ionization</li> 
                                <li>  Eliminates hazardous volatile substances
                                    (formaldehydes, ammonia, nitrogen oxides, etc.)</li> 
                                <li>Efficiency 40m3/h suitable for up to 20m2 offices</li> 
                                <li>3 modes: silent, normal, boost </li> 
                                <li>Small, modern cubic design fitting to any place</li> 
                                <li>Microprocessor controller with advanced control of purification modules 
                                    working time, temperature and flow level </li>
                                <li>Remote control </li> 
                                <li>Lifetime 50000h – no maintenance for 17 years (if used 8h/day) </li>
                                <li>Eco design – no mercury lamps, almost eliminated plastics</li>
                                <li>100% safety: no ozone produced, closed UVC reactor with photo-bio-safety certificate</li>
                            </ul>
                        <ProductSlider productImages={[AirBarSR100HImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Dimensions:</p>
                                <p>H: 235mm</p>
                                <p>W: 1050mm</p>
                                <p>D: 195mm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Weight: 15kg</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Voltage: 220-240V</p>
                                <p>Frequency: 50-60Hz</p>
                                <p>Power: 130W</p>
                                <p>IP20 (IEC 60529)</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={noiseIconImg} alt='icon'/>
                                <p>Noise level: {'<'} 48dB </p>
                                <p>Air flow: 3 modes:</p>
                                <p>Turbo mode - up to 100m3/h</p>
                                <p>Optimum mode - up to 50m3/h – Medium mode</p>
                                <p>Silent mode - up to 35m3/h</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <p>Lamps: 2 x Philips PLL 55W/4P</p>
                                <p>Driver: HF-P 254/255 TL5 HO/PLL III</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                            <p>MCU Controller: Lifetime, efficiency, air flow</p>
                            <p>VDE Class: 100  </p>
                        </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Disinfection technologies:</p>
                                <ul>
                                    <li>PP filter or HEPA filter (option)</li>
                                    <li>UVC disinfection</li>
                                    <li>PCO disinfection</li>
                                </ul>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={filterIconImg} alt='icon'/>
                                <img className='productParametersIcon' src={UVC_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={MinusIconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default AirBarSR100H;