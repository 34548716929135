import React from 'react';

class Line  extends React.Component {
    render () {
        return (
            <div style={{backgroundColor: this.props.color1, width: this.props.lineWidth, height: this.props.lineHeight}} className='line'>
            <div style={{backgroundColor: this.props.color2}} className='line1'></div>
            <div style={{backgroundColor: this.props.color3}} className='line1'></div>
            </div>
        );
    }
}

export default Line;