import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';
import longProHumanImg from '../../graphics/products/longProHuman.jpg';

function LongProHuman () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Long Pro HCL'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                <p className='productCategories-textParagraph'>
                 LongPro HCL is a version of a lighting fixture designed for illuminating office spaces and production halls. It is controlled according to the circadian rhythm, taking into account periods of intense work, breaks, and the end of the workday. It offers the ability to adjust the color temperature within the range of 2700K to 6500K, as well as dimming functionality.
                 LongPro human is an innovative digitally controlled linear LED lamp that combines a twin-tube with a lighting fixture, using only power cables for control. The development of these two products in one allows for ultra-fast installation of the lamp without the need for disassembly. External, dual-sided lamp connectivity to the power source expedites the installation and disassembly process, which is particularly important in large facilities where lighting is installed on high ceilings. It is flicker-free. A single modern controller enables brightness control and color temperature adjustments tailored to the needs, allowing control over an entire group of lamps within a room.
                </p>
                <img style={{maxWidth: "800px"}} src={longProHumanImg} />
                <h1 style={{marginTop: "170px"}} className="siteInDevelopment">PRODUCT IS UNDER DEVELOPMENT</h1>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default LongProHuman;