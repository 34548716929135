import logo from '../graphics/logo-next-lighting-white.png';
import footerIconMail from '../graphics/icons/icon-mail-white.png';
import footerIconPhone from '../graphics/icons/icon-phone-white.png';
import footerIconLinkedin from '../graphics/icons/icon-linkedin-white.png';
import footerIconFacebook from '../graphics/icons/icon-facebook-white.png';
import footerIconYoutube from '../graphics/icons/icon-youtube-white.png';
import Line from './Line';

function Footer () {
    return(
        <div className='footer'>
            <div className='footerCentered'>
                <a href="/"><img className='footerLogo' src={logo} alt='Next lighting'/></a>
                <div className={'footerBox'}>
                    <h4 className='whiteText'>Our company</h4>
                    <Line color1='#dfc08d' color2='#69dbe1' color3='white' lineWidth='100%' lineHeight='1px' />
                    <p className='footerText'>NEXT Lighting Ltd</p>
                    <p className='footerText'>Chrzanowskiego 23 str.</p>
                    <p className='footerText'>87-100 Toruń, Poland</p>
                    <p className='footerText'>REGON: 389728125</p>
                    <p className='footerText'>VAT ID: PL8792733632</p>
                </div>
                <div className={'footerBox'}>
                    <h4 className='whiteText'>Contact</h4>
                    <Line color1='#dfc08d' color2='#69dbe1' color3='white' lineWidth='100%' lineHeight='1px'/>
                    <a className={'footerLink'} href='mailto:sales@nextlighting.eu'>
                        <img className='footerIcon' src={footerIconMail} alt='mail'/>
                        sales@nextlighting.eu
                    </a>
                    <a className={'footerLink'} href='tel:+48789036056'>
                        <img className='footerIcon' src={footerIconPhone} alt='+48 780 147 737'/>
                        +48 780 147 737
                    </a>
                    <a className={'footerLink'} href='https://www.linkedin.com/company/73946341/admin/notifications/all/' rel='noreferrer' target="_blank">
                        <img className='footerIcon' src={footerIconLinkedin} alt='LinkedIn'/>
                        LinkedIn
                    </a>
                    <a className={'footerLink'} href='https://www.facebook.com/Next-Lighting-Ltd-103384115441575' rel='noreferrer' target="_blank">
                        <img className='footerIcon' src={footerIconFacebook} alt='Facebook'/>
                        Facebook
                    </a>
                    <a className={'footerLink'} href='https://www.youtube.com/channel/UCZveMiUuRaB8O0ZPEVSZEYg' rel='noreferrer' target="_blank">
                        <img className='footerIcon' src={footerIconYoutube} alt='Youtube'/>
                        Youtube
                    </a>
                </div>
                <div className={'footerBox'}>
                    <h4 className='whiteText'>Copyrights</h4>
                    <Line color1='#dfc08d' color2='#69dbe1' color3='white' lineWidth='100%' lineHeight='1px' />
                    <p className='footerText'>© NEXT Lighting Ltd</p>
                    <a className={'footerLink'} href='https://nextlighting.eu/privacy-policy' rel='noreferrer' target="_blank">
                        Privacy policy
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;