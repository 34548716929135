
function PageTopNav () {
    const urls = new Array();
    let href = window.location.pathname;
    let hrefs = href.split('/');
    for (let i = 1; i < hrefs.length; i++)
    {
        let hrefUrl = "";
        for (let a = 1; a <= i; a++)
        {
            hrefUrl +='/'+hrefs[a];
        }
        let hrefTitle = hrefs[i];
        let firstLetter = hrefTitle.charAt(0);
        firstLetter = firstLetter.toUpperCase();
        hrefTitle = hrefTitle.substring(1);
        hrefTitle = firstLetter+hrefTitle;
        urls.push({text: hrefTitle, url: hrefUrl});
    }
    return(
        <div className='pageTopNav'>
        {
            urls.map((url, index) => { 
                if (index == 0)
                return <div key={index}>
                    <a className='pageTopNavHref' href={url.url}> {url.text} </a>
                    </div>
                else
                    return <div key={index}>
                    <span className='pageTopNavSepetator'>{'>'}</span>
                    <a className='pageTopNavHref' href={url.url}> {url.text} </a>
                    </div>
            })
        }
        </div>
    );
}
export default PageTopNav;