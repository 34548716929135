import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import longProImg from "../../graphics/products/long-pro-white.jpg";
import longProZoomImg from "../../graphics/products/longProZoom.png";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIconImg from '../../graphics/icons/CE_icon.png';
import ProductSlider from "../../components_en/ProductSlider";


function LongProWhiteBlueDigital () {
    return(
        <>
            <Header/>
            <TitleSection h1={'LongPro White + Blue Digital'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                <h3>Suitable for any type of breeding</h3>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>White and blue lamp with smooth dimming control via power cables 
                                - PLC technology</li>
                                <li>Possibility to set the brightness intensity from 0 to 100%</li>
                                <li>Very high lighting efficiency</li>
                                <li>The highest degree of dust and water resistance</li>
                                <li>Cable glands for quick installation without interfering with the interior of the lamp. Click-to-click installation.</li>
                                <li>Blue light is not visible to the birds, thanks to which they can rest 
                                properly, and employees can tidy up the hen houses at this time without scaring or 
                                stressing the poultry. In addition to its proven calming effect, blue light promotes weight 
                                gain in chickens</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[longProImg, longProZoomImg]}/>
                </div>
                <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Dimensions:</p>
                            <p>H: 1200mm</p>
                            <p>W: 38mm</p>
                            <p>D: 38mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                        <img className='productParametersIcon' src={weightIconImg} alt='icon'/>
                            <p>Weight: 500g</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Voltage: 230V 50Hz</p>
                            <p>Power: 22V</p>
                            <p>IP69 (IEC 60529)</p>
                            <p>LIGHT SOURCE: LED 50khrs</p>
                            <p>LIGHT OUTPUT: 2400lm, White 3000K</p>
                            <p>Red 300lm 625nm</p>
                            <p>Beam Angle 140°</p>
                            <p>CRI: 80</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Standards:</p>
                            <p>Safety EN 60598-1, EN 62471</p>
                            <p>Lamp is safety compliance with external regulation IEC 62560</p>
                            <p>Lamp is safe in normal application -10 to 40˚C </p>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default LongProWhiteBlueDigital;