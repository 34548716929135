import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import ProductSlider from '../../components_en/ProductSlider'
import TitleSection from "../../components_en/TitleSection";
import AgriJet4000Img from "../../graphics/products/AgriJet4000.png";
import AgriJetGImg from "../../graphics/products/AgriJetG.png";
import AgriJetHImg from "../../graphics/products/AgriJetH.png";
import AgriJet4000BackImg from "../../graphics/products/AgriJet4000Back.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIconImg from  '../../graphics/icons/weightIcon.jpg';
import UVIconImg from  '../../graphics/icons/UV_icon.gif';
import UVC_IconImg from  '../../graphics/icons/UVC_icon.jpg';
import MinusIconImg from  '../../graphics/icons/minusIcon.png';


function AgriJet4000 () {
    return(
        <>
            <Header/>
            <TitleSection h1={'AgriJet®4000'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
                <div className='productCategories-productPageContainer'>
                <div className="nonAvailable">
                    Available Q4/2024
                </div>
                    <span className='productCategories-productDescription'>
                    The AgriJet 4000 is an ultra-efficient device designed for surface disinfection in facilities used for animal husbandry. It utilizes the Coanda effect, which is a physical phenomenon where a stream of gas tends to adhere to the nearest surface, partition, or wall.
                    A small, freely flowing stream of air accelerates previously stationary air particles in its immediate vicinity, creating a low-pressure zone around itself. 
                    The device is equipped with UV-C fluorescent lamps with a total power of 2200 W. With a flow rate of 4000 m3/h and a dose of 12mJ/cm2, it effectively reduces the replication of viruses, such as avian flu, in poultry farms.
                    The AgriJet is constructed entirely from aluminum and stainless steel alloys, which are completely resistant to UV-C radiation, water, and ammonia found in agricultural facilities.
                    The device features an automatic dust removal system from partition surfaces.
                    The device can be cleaned using a high-pressure water stream.
                    </span>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                                <ul className="productCategories-secondSectionList">
                                    <li>Kills viruses, bacteria and mould</li> 
                                    <li>Reduces unpleasant odours</li>
                                    <li>Improves animal well-being through negative air ionization</li>
                                    <li>Eliminates hazardous volatile substances (ammonia, hydrogen sulphide, nitrogen oxides, etc.)</li>
                                    <li>Efficiency up to 4000m3/h</li> 
                                    <li>PCO lifetime 50 000h, easy UVC lamp replacement (every 9000h)</li>
                                    <li>Microprocessor controller with advanced control of purification modules working time, temperature and flow level</li>
                                    <li>DUST PROOF – No fan! No filters!</li>
                                    <li>100% safety: No Ozone produced, presence detectors</li>
                                </ul>
                        </div>
                        <ProductSlider productImages={[AgriJet4000Img, AgriJet4000BackImg, AgriJetGImg, AgriJetHImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>  
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Dimensions (whole system approx.):</p>
                                <p>H: 1.0m</p>
                                <p>W: 1.5m</p>
                                <p>D: 2.5m</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Weight</p>
                                <img className='productParametersIcon' src={weightIconImg} alt='icon'/>
                                <p>Approx. 60kg</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Voltage: 220-240V</p>
                                <p>Frequency: 50-60Hz</p>
                                <p>Power: 3500W</p>
                                <p>IP69: IEC 60529</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UVIconImg} alt='icon'/>
                                <p>Minimum irradiance: 32mJ/cm2</p>
                                <p>Lamps: 40 x Philips TLUVC55W</p>
                                <p>Driver: 20 x HF-P 254/255 TL5 HO/PLL III</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Disinfection technologies:</p>
                                <ul>
                                    <li>UVC surface disinfection</li>
                                    <li>PCO photocatalysis</li>
                                </ul>
                                <p>Working / storage temperature: +10C to 50C / -25C to 60C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UVC_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={MinusIconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default AgriJet4000;