import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import pro15Img from "../../graphics/products/pro15.png";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import ProductSlider from "../../components_en/ProductSlider";


function Pro15 () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Pro15'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                <span className='productCategories-productDescription'>
                Pro15 to elektroniczny regulator oświetlenia. Regulacja mocy osiągana jest poprzez „ucinanie fazy” znane również jako trailing edge. PRO-15 został zaprojektowany do obsługi obciążeń rezystancyjnych i pojemnościowych. Pozwala na manualną i automatyczną kontrolę oświetlenia za pomocą sygnału 0-10V, a nawet poprzez podłączenie jednobiegunowego przełącznika. Istnieje możliwość tworzenia pakietów do regulacji jasności w celu zwiększenia ogólnej pojemności mocy. Ściemniacz obsługuje źródła światła regulowane, takie jak żarówki żarowe, żarówki halogenowe 230V AC, żarówki halogenowe o niskim napięciu zasilane przez transformatory elektroniczne, energooszczędne żarówki kompaktowe (CFL) i lampy fluorescencyjne zimnokatodowe (CCFL), a także żarówki LED. Instalacja ściemniacza jest prosta i szybka, a jego obsługa jest w 100% intuicyjna. Ściemniacz jest dostępny w obudowie montowanej na szynie TH 35 mm. Maksymalna moc obciążenia to 1500W. Cyfrowy wyświetlacz dostarcza informacji na temat jego statusu, funkcji bezpieczeństwa, a jego funkcje można programować za pomocą przycisków. Posiada również wbudowane zabezpieczenia elektroniczne przed przeciążeniem, zwarciami, przegrzaniem i przepięciami w sieci. Oprogramowanie umożliwia symulację wschodu i zachodu słońca, znane jako SLS (Symulacja Wschodu i Zachodu Słońca).
                </span>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Elektroniczny regulator oświetlenia oparty na tranzystorach</li>
                                <li>Regulacja manualna lub za pomocą zewnętrznego kontrolera 0-10V (PC, PLC, SLS1+, itp.)</li>
                                <li>Możliwość równoległego połączenia w celu zwiększenia ogólnej mocy</li>
                                <p>Reguluje różne typy źródeł światła::</p> 
                                <ul>
                                    <li>Żarówki żarowe i żarówki halogenowe 230V AC</li>
                                    <li>Żarówki halogenowe o niskim napięciu zasilane przez transformator elektroniczny</li>
                                    <li>Żarówki LED regulowane</li>
                                    <li>Dimmable LED bulbs</li>
                                </ul>
                                <li>Informacje o stanie i błędach na wyświetlaczu LED</li>
                                <li>Wyjście alarmowe</li>
                                <li>Łatwa, intuicyjna obsługa z możliwością własnej konfiguracji</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[pro15Img]}/>
                </div>
                <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Wymiary (całego systemu):</p>
                                <p>H: 85mm</p>
                                <p>W: 105mm</p>
                                <p>D: 60mm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Waga: 280g</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Napięcie znamionowe: 100-230V 50/60Hz</p>
                                <p>Maksymalna moc obciążenia: 1500VA</p>
                                <p>Metoda montażu: szyna DIN-35 rail</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Wbudowane zabezpieczenia:</p>
                                <p>- Zabezpieczenie przed przeciążeniem</p>
                                <p>- Zabezpieczenie przed zwraciem</p>
                                <p>- Zabezpieczenie przed przegrzaniem</p>
                                <p>- Ochrona przed wysokim napięciem</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Standardy:</p>
                                <p>Bezpieczeństwo EN 60598-1, EN 62471</p>
                                <p>EMC EN 55022</p>
                                <p>Spełnia wymogi bezpieczeństwa w zakresie temperatur pracy: -10 - 45˚</p>
                                <p>IP20 (IEC 60529)</p>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                    </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default Pro15;