import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/App.css';
import '../styles/Products.css'
import warranty from "../graphics/icons/warranty.webp"

import animalCentricLightingImg from "../graphics/animal-centric-lighting.jpg";
import humanCentricLightingImg from '../graphics/human-centric-lighting.jpg';
import uvaInsectTrapsImg from '../graphics/uva-insect-traps.jpg';
import uvaAirDesinfectionImg from '../graphics/uvc-air-disinfection.jpg';

function Products () {
    return (
        <>
            <Header/>
            <TitleSection h1={'PRODUCTS'} h2={''} height='280px'/>
            <div id="products-secondSection">
                <div className='products-secondSectionContainer'>
                    <a href="/products/animal-centric-lighting">
                    <div style={{backgroundImage: `url(${animalCentricLightingImg})`}} className='products-productCategoryBox'>
                        <h4 className='products-productCategoryBoxHeader'>ANIMAL CENTRIC LIGHTING</h4>
                    </div>
                    </a>
                    <a href="/products/human-centric-lighting">
                    <div style={{backgroundImage: `url(${humanCentricLightingImg})`}} className='products-productCategoryBox'>
                        <h4 className='products-productCategoryBoxHeader'>HUMAN CENTRIC LIGHTING</h4>
                    </div>
                    </a>
                </div>
                <div className='products-secondSectionContainer'>
                    <a href="/products/uvc-air-desinfection">
                    <div style={{backgroundImage: `url(${uvaAirDesinfectionImg})`}} className='products-productCategoryBox'>
                        <h4 className='products-productCategoryBoxHeader'>UVC AIR DISINFECTION</h4>
                    </div>
                    </a>
                    <a href="/products/uva-insect-traps">
                    <div style={{backgroundImage: `url(${uvaInsectTrapsImg})`}} className='products-productCategoryBox'>
                        <h4 className='products-productCategoryBoxHeader'>UVA INSECT TRAPS</h4>
                    </div>
                    </a>
                </div>
                <h4><img style={{width: "90px"}}src={warranty}/>The WARRANTY for all our products is 2 years.</h4>
            </div>
            <Footer/>
        </>
    );
}

export default Products;