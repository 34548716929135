import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import UVATubeImg from '../../graphics/products/UVA_tubes.jpg';
import wingsFlippering from '../../graphics/wings_flippering.jpg';
import ProductSlider from '../../components_en/ProductSlider'


function T89WFlyWingsSimulation () {
    return(
        <>
            <Header/>
            <TitleSection h1={'T8-9W z symulacją ruchu skrzydeł much'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div style={{width: '70%'}} className='productCategories-description'>
            Jest to unikatowa wersja lampy T8 LED opracowana na podstawie badań naukowych. 
            Strumień UV światła nie jest stały a zostaje zmodulowany w taki sposób aby był bardziej atrakcyjny dla much w porównaniu do standardowej lampy o tej samej mocy znamionowej.
            Mikrokontroler, który steruje pracą lampy cały czas zmienia parametry światła zgodnie z ustalonym algorytmem, który powstał na podstawie badań.
            Zmiany światła są niewidoczne dla ludzkiego oka za to atrakcyjne dla owadów, które posiadają wzrok o znacznie krótszym czasie  reakcji i  większej częstotliwości przetwarzania obrazu. 
            </div>
            <div className='productCategories-productPageContainer'>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                            <li>Zaprojektowana i wyprodukowana w UE</li>
                            <li>Diody najwyższej jakości pochodzące z Korei</li>
                            <li>Trwałość 20 000 godzin</li>
                            <li>Wysoka skuteczność w łapaniu much potwierdzona badaniami</li>
                            <li>Najwyższa skuteczność - skuteczniejsze łapanie much poprzez symulację ruchu skrzydeł much</li>
                            <li>100% konstrukcji i produkcji w Polsce</li>
                            <li>Jednorodne światło bez zaciemnień (optyka z Niemiec)</li>
                            <li>Atrakcyjna cena</li>
                            <li>Gwarancja 2 lata</li>
                            </ul>
                        </div>
                        <ProductSlider productImages={[UVATubeImg]}/>
                        <div className="productCategories-productImageWithDescription">
                            <h4>Symulacja ruchu skrzydeł much</h4>
                            <img style={{maxWidth: "500px", height: "auto", marginTop: "10px", marginBottom: "20px"}} src={wingsFlippering} className="productCategories-productImage" />
                            <p>Źródło zdjęć: ’How flies are flirting on the fly’, February 2017 BMC Biology 15(2)Follow journal; DOI: 10.1186/s12915-016-0342-6</p>
                        </div>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <p>Wymiary:</p>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Średnica 25.8mm L: 450 mm</p>
                                <p>L: 450mm</p>                                          
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Napięcie znamionowe: 230V</p>
                                <p>Moc: 10W</p>
                                <p>Prąd: 50mA</p>
                                <p>IP20 (IEC 60529) </p>
                                <p>UVA: 3.7W</p>
                                <p>UVA LED: 370.5 nm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Temperatura pracy: -40°C - 45°C</p>
                                <p>Temperatura przechowywania: -45°C - 65°C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                        </div>
        </div>
        <Footer/>
        </>
    );
}

export default T89WFlyWingsSimulation;