import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import xaraImg from "../../graphics/products/xaraE27bulb.jpg";
import xara_backImg from "../../graphics/products/xaraE27bulb_back.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';
import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import ProductSlider from "../../components_en/ProductSlider";



function Xara () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Xara'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <h3>Suitable for any type of breeding</h3>
                    <div className='productCategories-productDescription'>
                        This is our only bulb designed to operate in very high temperatures, up to +60 degrees Celsius. It can be used in any fixture, allowing for easy retrofitting of various lighting installations in a range of agricultural facilities. Xara boasts above-average luminous efficacy, directly translating into energy savings. The bulb emits light at a color temperature of 2900K.
                    </div>
                    <div className='productCategories-descriptionAndParameters'>
                        <ul className="productCategories-secondSectionList">
                                <li>6W dimmable bulb</li>
                                <li>Efficacy 135lm/W</li>
                                <li>Equivalent to a 60W bulb</li>
                                <li>Emitting a color temperature of 2900K (warm white light)</li>
                                <li>Best dimming performance on the market (super smooth dimming)</li>
                                <li>No flickering</li>
                                <li>Very long lifetime</li>
                            </ul>
                            <ProductSlider productImages={[xaraImg, xara_backImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Dimensions:</p>
                                <p>H: 120mm</p>
                                <p>W: 63mm</p>
                                <p>D: 63mm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Weight: 58g</p>
                                <p>DIMMABLE by trailing Edge
                                phase cut dimmers
                                </p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Voltage: 230V 50/60Hz</p>
                                <p>Power: 6W</p>
                                <p>LIGHT SOURCE:  LED 50khrs</p>
                                <p>LIGHT OUTPUT: 820lm White 2900K</p>
                                <p>Beam Engle 170°</p>
                                <p>CRI: {'>'} 80</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Standards:</p>
                                <p>Safety EN 60598-1, EN 62471</p>
                                <p>The bulb is safe to use under conditions -10 ÷ 50˚C </p>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                            
                        </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default Xara;