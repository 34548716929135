import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';
import skyPanelImg from '../../graphics/products/skyPanel.jpg';


function SkyPanel () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Sky Panel'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
            <p className='productCategories-textParagraph'>
            Sky Panel is a technologically advanced LED panel with the capability to adjust the color temperature of the light according to the natural circadian rhythm of humans. It also features a unique, globally unprecedented function of creating a spatial cloud flow effect in the sky.
A single panel, measuring 60x60 cm, can be part of a larger surface, such as the ceiling of a conference hall or office. It can be installed in various configurations, for example, in a 10x10 arrangement. These panels communicate with each other, creating a unified whole. There's the option to modify the displayed clouds, control the direction and intensity of the wind, and more.
Being in an enclosed space, it provides the sensation of working in the midst of nature. It reduces stress, enhances mood, and fosters positive interpersonal relationships.
            </p>
            <img style={{maxWidth: "800px"}} src={skyPanelImg} />
            <h1 className="siteInDevelopment">PRODUCT IS UNDER DEVELOPMENT</h1>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default SkyPanel;