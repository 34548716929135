import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import UVATubeImg from '../../graphics/products/UVA_tubes.jpg';
import DRF_Icon from '../../graphics/products/T8-10W-DRF.png';
import ProductSlider from '../../components_en/ProductSlider'


function T89WDRF () {
    return(
        <>
            <Header/>
            <TitleSection h1={'T8 9W DFR  (Direct   Fluorescent Replacement)'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <div className='productCategories-descriptionAndParameters'>
                    <span className='productCategories-productDescription'>
                    Jest to unikatowa wersja lampy T8 LED która jest w stanie zastąpić standardową lampę fluorescencyjną w oprawach pojedynczych oraz podwójnych z magnetycznym balastem.
Do wymiany lampy nie jest konieczna żadna przeróbka standardowej oprawy. 
W pudełku znajduje się lampa T8 LED oraz dodatkowo „LED starter” który należy wymienić w oprawie. 
Oprócz pojedynczej oprawy lampa ma unikatową w skali światowej zdolność pracy w oprawie podwójnej w której lampy fluorescencyjne były połączone szeregowo. Wówczas na każdej z lamp występuje połowa napięcia a lampy T8 w dalszym ciągu świecą wciąż z tą samą mocą znamionową. Specjalnie  zaprojektowany  przez nasz dział R&D driver lampy zapewnia stabilną pracę oraz równomierne rozłożenie mocy obydwu lamp.

                    </span>
                        <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                            <li>Zaprojektowana i wyprodukowana w UE</li>
                            <li>Diody najwyższej jakości pochodzące z Korei</li>
                            <li>Trwałość 20 000 godzin</li>
                            <li>Wysoka skuteczność w łapaniu much potwierdzona badaniami</li>
                            <li>100% konstrukcji i produkcji w Polsce</li>
                            <li>Jednorodne światło bez zaciemnień (optyka z Niemiec)</li>
                            <li>Atrakcyjna cena</li>
                            <li>LED starter dołączony w pudełku.</li>
                            <li>Gwarancja 2 lata</li>
                            </ul>
                        </div>
                        <ProductSlider productImages={[DRF_Icon]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                                <p>Wymiary:</p>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Średnica 25.8mm</p>
                                <p>L: 450mm</p>                                          
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Napięcie: 230V</p>
                                <p>Moc: 9W</p>
                                <p>Prąd: 50mA</p>
                                <p>IP20 (IEC 60529) </p>
                                <p>UVA: 3.7W</p>
                                <p>UVA LED: 370.5 nm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Temperatura pracy: -40C do 45C</p>
                                <p>Temperatura przechowywania: -45C do 60C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                        </div>
        </div>
        <Footer/>
        </>
    );
}

export default T89WDRF;