import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import T8Img from '../graphics/products/UVA_tubes.jpg';

import ProductBox from "../components_en/ProductBox";

import DRF from '../graphics/products/T8-10W-DRF.png';


function UVTubes () {
    return(
        <>
        <Header/>
        <TitleSection h1={'Lampy trzonkowe UVA'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
        <span className="productCategories-productGroupDescription">
        Jest to wersja LED standardowej tuby jarzeniowej, świecąca w zakresie promieniowania UVA, będąca dla niej zamiennikiem o znacznie dłuższej żywotności. Energooszczędna, cechująca się specjalnym tworzywem przepuszczającym zakres promieniowania ultrafioletowego. Lampa ta jest przeznaczona do zastosowania głównie w pułapkach dla owadów. Standardowa długość to 450mm. Istnieje możliwość indywidualnego wykonania o innych długościach oraz mocy znamionowej, zgodnie z wymaganiami klienta.
        </span>
            <div id='productCategories-productsGrid'>
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-9w'} productImg={T8Img} productTitle='T8 9W' productCategory='productCategories-UVA'/>
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-10w'} productImg={T8Img} productTitle='T8 10W' productCategory='productCategories-UVA'/>
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-13w'} productImg={T8Img} productTitle='T8 13W' productCategory='productCategories-UVA'/>    
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-9w-flywings-simulation'} productImg={T8Img} productTitle='T8-9W z symulacją ruchu skrzydeł much' productCategory='productCategories-UVA'/> 
            <ProductBox productPageHref={'/products/uva-insect-traps/uva-tubes/t8-9w-dfr'} productImg={DRF} productTitle='T8 9W DFR  (Direct Fluorescent Replacement)' productCategory='productCategories-UVA'/>                    
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default UVTubes;