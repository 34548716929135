import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import longProImg from "../../graphics/products/long-pro-white.jpg";
import longProZoomImg from "../../graphics/products/longProZoom.png";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIconImg from '../../graphics/icons/CE_icon.png';
import ProductSlider from "../../components_en/ProductSlider";


function LongProWhiteBlueDigital () {
    return(
        <>
            <Header/>
            <TitleSection h1={'LongPro White + Blue Digital - LongPro biało-niebieski -cyfrowy'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                <h3>Nadaje się do każdego rodzaju hodowli</h3>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Biało - niebieska lampa z płynną regulacją jasności za pomocą kabli zasilających - technologia PLC</li>
                                <li>Możliwość ustawienia intensywności jasności od 0 do 100%</li>
                                <li>Bardzo wysoka wydajność oświetleniowa</li>
                                <li>Najwyższy stopień odporności na kurz i wodę</li>
                                <li>Przewody zaciskowe umożliwiające szybki montaż bez ingerencji w wnętrze lampy. Instalacja z krokiem na kliknięcie.</li>
                                <li>Niebieskie światło nie jest widoczne dla ptaków, dzięki czemu mogą one spokojnie odpoczywać, a pracownicy mogą w tym czasie porządkować kurniki bez straszenia lub stresowania drobiu. Oprócz udowodnionego działania uspokajającego, niebieskie światło sprzyja również przyrostowi masy ciała u kurcząt.</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[longProImg, longProZoomImg]}/>
                </div>
                <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Wymiary:</p>
                            <p>H: 1200mm</p>
                            <p>W: 38mm</p>
                            <p>D: 38mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                        <img className='productParametersIcon' src={weightIconImg} alt='icon'/>
                            <p>Waga: 500g</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Napięcie znamionowe: 230V 50Hz</p>
                            <p>Moc: 22V</p>
                            <p>IP69 (IEC 60529)</p>
                            <p>Żywotność źródła świata: LED 50 tys. godzin</p>
                            <p>Parametry świetlne: 2400lm, White 3000K</p>
                            <p>Czerwony 300lm 625nm</p>
                            <p>Kąt rozsyłu 140°</p>
                            <p>CRI: 80</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Standardy:</p>
                            <p>Bezpieczeństwo EN 60598-1, EN 62471</p>
                            <p>Lampa spełnia wymogi bezpieczeństwa zgodnie z IEC 62560</p>
                            <p>Lampa spełnia wymogi bezpieczeństwa w zakresie temperatur pracy -10 - 40˚C </p>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default LongProWhiteBlueDigital;