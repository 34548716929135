import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import UVCChamberSImg from "../../graphics/products/UVC-Chamber-S.jpg";
import UVCChamberSBackImg from "../../graphics/products/UVC-Chamber-SBack.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIconImg from  '../../graphics/icons/weightIcon.jpg';
import UVC_IconImg from  '../../graphics/icons/UVC_icon.jpg';
import MinusIconImg from  '../../graphics/icons/minusIcon.png';
import ProductSlider from "../../components_en/ProductSlider";


function UVC_ChamberS () {
    return(
        <>
            <Header/>
            <TitleSection h1={'UVC Chamber S'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
            <span className='productCategories-productDescription'>
            Komora UV-C to urządzenie do dezynfekcji, wykorzystujące światło UV-C do dezynfekcji odzieży i innych codziennych przedmiotów. Technologia UV-C używana nie tylko zapewnia potężne zdolności dezynfekcyjne przeciwko patogenom, ale także eliminuje emisję szkodliwego ozonu, który może stanowić potencjalne zagrożenie dla zdrowia. Proces dezynfekcji jest przyjazny dla środowiska i nie pozostawia żadnych śladów. Urządzenie wyposażone jest w moduł fotokatalityczny (PCO), który redukuje nieprzyjemne zapachy na zdezynfekowanych powierzchniach. Dodatkową zaletą jest możliwość korzystania z dostosowywalnego dystrybutora zapachów. Urządzenie używa nowoczesnych lamp o łącznej mocy 120W, generujących silne promieniowanie bakteriobójcze. To promieniowanie trwale uszkadza zdolność wirusów i bakterii do namnażania się na zdezynfekowanych powierzchniach. W zaledwie kilka chwil skutecznie eliminuje szkodliwe patogeny, takie jak wirusy, bakterie i grzyby, w tym SARS-CoV-2 i wirus grypy. Urządzenia wykonane jest ze stali nierdzewnej. Wnętrze komory wykonane jest z wysokopołyskowego aluminium, co pozwala na odbicie ponad 95% światła emitowanego przez lampy fluorescencyjne. To maksymalizuje ekspozycję na promieniowanie UV-C, zapewniając skuteczną i szybką dezynfekcję. Obsługa urządzenia jest łatwa i polega na włączeniu zasilania oraz ustawieniu czasu cyklu. Po otwarciu drzwi użytkownik umieszcza przedmioty na wyznaczonej tacy, zamyka drzwi i ustawia odpowiedni czas, wrzucając wymaganą ilość monet do otworu na monety. Komora UV-C nie tylko jest wyjątkowo bezpieczna w zamkniętych przestrzeniach, ale także wyposażona w liczne wbudowane zabezpieczenia, które minimalizują ryzyko przypadkowego narażenia na promieniowanie UV-C, w tym osłony lamp chroniące przed przypadkowym uszkodzeniem.
            </span>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Zabija wirusy, bakterie i pleśń (COVID-19, ASF, ptasia grypa w kilka sekund)</li>
                                <li>Dezynfekcja powierzchni (telefony, narzędzia, dokumenty, itp.)</li>
                                <li>Redukuje nieprzyjemne zapachy (ubrania, itp.)</li>
                                <li>Eliminuje szkodliwe lotne substancje (formaldehydy, amoniak, tlenki azotu, itp.)</li>
                                <li>Wysoka radiacja UVC</li>
                                <li>Mikroprocesorowy kontroler z zaawansowaną kontrolą czasu pracy, temperatury i poziomu przepływu modułów oczyszczania</li>
                                <li>Wysoka wydajność lamp UVC firmy Philips</li>
                                <li>Eco-design – solidna konstrukcja, prawie pozbawiona plastiku</li>
                                <li>100% bezpieczeństwo: Brak produkcji ozonu, podwójne zabezpieczenie otwarcia.</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[UVCChamberSImg, UVCChamberSBackImg]}/>
                </div>
                <div className='productCategories-productParameters'>  
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Wymiary:</p>
                            <p>H: 900mm</p>
                            <p>W: 1020mm</p>
                            <p>D: 800mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Masa</p>
                            <img className='productParametersIcon' src={weightIconImg} alt='icon'/>
                            <p>Około 40kg</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Napięcie znamionowe: 220-240V</p>
                            <p>Częstotliwość: 50-60Hz</p>
                            <p>Moc: 130W</p>
                            <p>IP20: IEC 60529</p>
                            <p>Minimum irradiance: {'>'} 250 mJ / cm2</p>
                            <p>Lampy: 2 x Philips TUV PL-L 60W/4P HO</p>
                            <p>Sterownik: IUP-2S60-M4LD</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Temperatura pracy/przechowywania: 18°C - 40°C / -25°C - 60°C</p>
                            <p>Technologie dezynfekcji:</p>
                            <ul>
                                <li>Powierzchniowa dezynfekcja UVC</li>
                                <li>Dezynfekcja PCO (opcjonalna)</li>
                            </ul>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={UVC_IconImg} alt='icon'/>
                            <img className='productParametersIcon' src={MinusIconImg} alt='icon'/>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                    </div>
                    </div>
        </div>
        <Footer/>
        </>
    );
}

export default UVC_ChamberS;