import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import noiseIconImg from '../../graphics/icons/noiseIcon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import RemoteControllerIconImg from '../../graphics/icons/RemoteControllerIcon.png';
import filterIconImg from '../../graphics/icons/filterIcon.jpg';
import UVC_IconImg from  '../../graphics/icons/UVC_icon.jpg';
import MinusIconImg from  '../../graphics/icons/minusIcon.png';
import AirBar40TransportImg from '../../graphics/products/AirBar40Transport.jpg';
import Pic1Img from '../../graphics/products/AirBarTransport/Pic1.jpg';
import Pic2Img from '../../graphics/products/AirBarTransport/Pic2.jpg';
import Pic3Img from '../../graphics/products/AirBarTransport/Pic3.jpg';

import ProductSlider from "../../components_en/ProductSlider";
import AirBar40TransportTechnicImg from '../../graphics/products/AirBar40TransportTechnic.png';


function AirBar40Transport () {
    return(
        <>
            <Header/>
            <TitleSection h1={'AirBar® SR40 Transport'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
                        <div className='productCategories-productPageContainer'>
                        <div className='productCategories-airPaintMount'>
                                <div className='productCategories-pictureWithDescription'>
                                    <img src={Pic1Img}/>
                                    <h4>Windy</h4>
                                </div>
                                <div className='productCategories-pictureWithDescription'>
                                    <img src={Pic2Img}/>
                                    <h4>Autobusy/Pociągi</h4>
                                </div>
                                <div className='productCategories-pictureWithDescription'>
                                    <img src={Pic3Img}/>
                                    <h4>Ambulanse</h4>
                                </div>
                        </div>
                        <span style={{width: "80%"}} className='productCategories-productGroupDescription'>
                        AirBar40 to unikalne urządzenie wykorzystujące promieniowanie UV-C do dezynfekcji środków transportu - samochodów osobowych i dostawczych, tramwajów, pociągów i wind. Jego zadaniem jest oczyszczanie przepływającego powietrza z patogenów, substancji toksycznych, wirusów, bakterii, pleśni i gazów o "nieprzyjemnym zapachu". Składa się z modułów PCO i UV-C, redukujących zanieczyszczenie o 99.99%. To szczególnie istotne w transporcie medycznym i weterynaryjnym, transporcie publicznym oraz transporcie produktów rolno-spożywczych. Urządzenie można montować pionowo lub poziomo i zostało zaprojektowane do dezynfekcji powietrza o przepływie około 40 m3/h.
                </span>
                        <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                                <ul className="productCategories-secondSectionList">
                                    <li>Zabija wirusy, bakterie i pleśń</li> 
                                    <li>Redukuje nieprzyjemne zapachy</li>
                                    <li>Silnik oczyszczający oparty na diodach LED UV</li>
                                    <li>Brak szkła, brak rtęci, odporność na wstrząsy i wibracje</li>
                                    <li>Wydajność 40 m3/h, około 5 wymian powietrza na godzinę w ambulansie</li>
                                    <li>Komora oczyszczająca (reaktor) wykonana z materiału silnie odbijającego promieniowanie UVC</li>
                                    <li>Ograniczona konserwacja (żywotność modułu UV-C do 50 000 godzin)</li>
                                    <li>Moduł PCO (Fotokataliza Utlenianie) żywotność do 50.000 godzin</li>
                                    <li>Jednostka wyposażona w sterownik mikroprocesorowy z zaawansowaną kontrolą czasu pracy modułów oczyszczających, temperatury i poziomu przepływu</li>
                                    <li>Sygnalizacja stanu pracy diodą LED</li>
                                    <li>Nie wytwarza ozonu</li>
                                </ul>
                        </div>
                        <ProductSlider productImages={[AirBar40TransportImg, AirBar40TransportTechnicImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Wymiary:</p>
                                <p>Montaż: / Przedni panel</p>
                                <p>H: 600mm / 621mm</p>
                                <p>W: 180mm / 165mm</p>
                                <p>D: 56mm / 59mm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Waga: 2.5kg</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Napięcie znamionowe: 12-19V DC</p>
                                <p>Moc: 60W</p>
                                <p>IP20 (IEC 60529)</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={noiseIconImg} alt='icon'/>
                                <p>Poziom hałasu: {'<'} 39dB </p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                                <p>Przepływ powietrza: do to 40m3/h</p>
                                <p>Źródło UVC: UVC LED Module 50khrs</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={RemoteControllerIconImg} alt='icon'/>
                                <p>Kontroler MCU: żywotność, wydajność, przepływ powietrza</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Technologie dezynfekcji:</p>
                                <ul>
                                    <li>filtr PP lub HEPA (do wyboru)</li>
                                    <li>dezynfekcja UVC</li>
                                    <li>dezynfekcja PCO</li>
                                </ul>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={filterIconImg} alt='icon'/>
                                <img className='productParametersIcon' src={UVC_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={MinusIconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                    </div>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default AirBar40Transport;