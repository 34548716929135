import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import proBoxImg from "../../graphics/products/proBox.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import ProductSlider from "../../components_en/ProductSlider";


function ProBox () {
    return(
        <>
            <Header/>
            <TitleSection h1={'ProBox'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                <span className='productCategories-productDescription'>
                ProBox  - Jest to ściemniacz i kontroler w jednym, wyposażony w wyświetlacz graficzny LCD informujący o jego stanie oraz działaniu zabezpieczeń i funkcji. Regulacja mocy odbywa się poprzez wycinanie fazy, tzw. trailing edge. Przystosowany jest do obciążeń rezystancyjnych i pojemnościowych. Kontroler umożliwia regulację oświetlenia w trybie manualnym oraz automatycznym, poprzez ustawienie dobowej krzywej regulacji oświetlenia. Ściemniacz obsługuje źródła światła przeznaczone do ściemniania tj. zwykłe żarówki, żarówki halogenowe 230 V AC, niskonapięciowe żarówki halogenowe zasilane z transformatorów elektronicznych, regulowane żarówki energooszczędne CFL, żarówki energooszczędne z zimną katodą CCFL, żarówki LED.   
                Maksymalne jego obciążenie to 1000W. 
                Posiada on wbudowane elektroniczne zabezpieczenia: przed przeciążeniem, zwarciem, przegrzaniem, przepięciami w sieci.  

                        <br></br><br></br>
                        Kontroler posiada oprogramowanie umożliwiające rozbudowaną symulację wschodów i zachodów słońca, ustawianie dowolnej jasności oświetlenia w dowolnym czasie i momencie. Wbudowany zegar 24/7 z pełnym kalendarzem i układem podtrzymania pamięci. Wyposażony w trzy wyjścia do kontroli zewnętrznych urządzeń:
                        <ul>
                            <li>Wyjście do sterowania centrali alarmowej lub syreny alarmowej.</li>
                            <li>Wyjście do sterowania zewnętrznego przekaźnika np. do galwanicznego rozłączania oświetlenia</li>
                            <li>Wyjście 0-10V do sterowania kolejnych ściemniaczy lub innych urządzeń. Napięcie na tym wyjściu jest proporcjonalne do ustawionego natężenia oświetlenia.  </li>
                        </ul>
                </span>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Połączenie wybranych funkcji ściemniacza z kontrolerem</li>
                                <li>Wbudowany ściemniacz 1000W</li>
                                <li>Kontroler do symulacji wschodów i zachodów słońca</li>
                                <li>Możliwość sterowania zewnętrznymi regulatorami oświetlenia przy użyciu interfejsu 1-10V</li>
                                <li>Wbudowane pełne zabezpieczenia elektroniczne</li>
                                <li>Wyjście do sterowania systemem alarmowym</li>
                                <li>Wyjście na zewnętrzny przekaźnik</li>
                                <li>Pełny kalendarz 7/24</li>
                                <li>Możliwość ustawienia 16 poziomów na dzień</li>
                                <li>Wysoki stopień odporności na kurz i wodę</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[proBoxImg]}/>
                </div>
                <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Wymiary (całego systemu):</p>
                            <p>H: 200mm</p>
                            <p>W: 150mm</p>
                            <p>D: 85mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Waga: 700g</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Napięcie znamionowe: 100-230V 50/60Hz</p>
                            <p>Maksymalna moc obciążenia: 1000VA</p>
                            <p>IP56 (IEC 60529)</p>
                            <p>Montaż: na ścianie</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Wbudowane zabezpieczenia:</p>
                            <p>- Zabezpieczenie przed przeciążeniem</p>
                            <p>- Zabezpieczenie przed zwraciem</p>
                            <p>- Zabezpieczenie przed przegrzaniem</p>
                            <p>- Ochrona przed wysokim napięciem</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Standardy:</p>
                            <p>Bezpieczeństwo EN 60598-1, EN 62471</p>
                            <p>EMC EN 55022</p>
                            <p>Spełnia wymogi bezpieczeństwa w zakresie temperatur pracy -10 - 45˚</p>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default ProBox;