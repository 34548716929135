import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import sunProImg from "../../graphics/products/sun-pro.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import ProductSlider from "../../components_en/ProductSlider";


function SunPro () {
    return(
        <>
            <Header/>
            <TitleSection h1={'SunPro'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <span className='productCategories-productDescription'>
                    SunPro controller allows to program light scenes 
                    such as sunrises and sunsets. Simulations of 
                    natural daily cycles allow you to create a 
                    sufficiently close to natural environment in 
                    poultry farming. By properly adjusting simulated 
                    sunrises and sunsets, we can, for example, 
                    increase the efficiency of egg production in a 
                    hen house.
                    </span>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                            <p>
                            The controller enables the following functions:
                            </p>
                                <ul className="productCategories-secondSectionList">
                                    <li>Simulation of sunrises and sunsets</li>
                                    <li>Lighting programs</li>
                                    <li>Integrated clock with numeric display</li>
                                    <li>Ability to set two inclusions per day</li>
                                    <li>Possibility to control manual and automatic mode</li>
                                    <li>Electronics controlled by a microprocessor</li>
                                    <li>The analog output allows the connection of up to 200 adjustable lamps (1-10V interface) or regulating devices (0-10V)</li>
                                </ul>
                        </div>
                        <ProductSlider productImages={[sunProImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Dimensions (whole system approx.):</p>
                                <p>H:  85mm</p>
                                <p>W: 105mm</p>
                                <p>D: 60mm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Weight: 250g</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Voltage: 100-230V 50/60Hz</p>
                                <p>Power consump: 6W</p>
                                <p>Working temperature: -10C to 45C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Standards:</p>
                                <p>Safety EN 60598-1, EN 62471</p>
                                <p>EMC EN 55022</p>
                                <p>The bulb is safe to use under conditions -10 - 50˚C </p>
                                <p>IP20 (IEC 60529)</p>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                    </div>
                    </div>
        </div>
        <Footer/>
        </>
    );
}

export default SunPro;