import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';
import longProHumanImg from '../../graphics/products/longProHuman.jpg';

function LongProHuman () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Long Pro HCL'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                <p className='productCategories-textParagraph'>
                Wersja lampy do oświetlania pomieszczeń biurowych oraz hal produkcyjnych. Sterowania jest zgodnie z rytmem dobowym uwzględniającym okresy wytężonej pracy, przerw oraz jej zakończenia. Posiada możliwość zmiany temperatury barwowej w zakresie 2700K do 6500K oraz funkcję ściemniania. 
                LongPro human to innowacyjna lampa linearna LED sterowana cyfrowo, która za pośrednictwem przewodów zasilających łączy w sobie tubę  dwutrzonkową z oprawą oświetleniową. Opracowanie 2 produktów w 1 umożliwia ultraszybki montaż lampy, bez konieczności jej rozbierania na części. Sprawę przyspiesza zewnętrzne, możliwe obustronne podłączenie lampy do sieci, co ma ogromne znaczenie w montażu i demontażu na dużych obiektach, gdzie oświetlenie instalowane jest na wysokich sufitach. Jest bezmigotliwa. Pojedynczy nowoczesny sterownik umożliwia kontrolę jasności oraz zmianę temperatury barwowej adekwatną do potrzeb, umożliwiając sterowanie całą grupą lamp w pomieszczeniu.

                </p>
                <img style={{maxWidth: "800px"}} src={longProHumanImg} />
                <h1 style={{marginTop: "170px"}} className="siteInDevelopment">Produkt jest w fazie rozwoju</h1>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default LongProHuman;