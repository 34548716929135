import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import ProductSlider from '../../components_en/ProductSlider'
import TitleSection from "../../components_en/TitleSection";
import AgriJet4000Img from "../../graphics/products/AgriJet4000.png";
import AgriJetGImg from "../../graphics/products/AgriJetG.png";
import AgriJetHImg from "../../graphics/products/AgriJetH.png";
import AgriJet4000BackImg from "../../graphics/products/AgriJet4000Back.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIconImg from  '../../graphics/icons/weightIcon.jpg';
import UVIconImg from  '../../graphics/icons/UV_icon.gif';
import UVC_IconImg from  '../../graphics/icons/UVC_icon.jpg';
import MinusIconImg from  '../../graphics/icons/minusIcon.png';


function AgriJet4000 () {
    return(
        <>
            <Header/>
            <TitleSection h1={'AgriJet®4000'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
                <div className='productCategories-productPageContainer'>
                <div className="nonAvailable">
                    Dostępny Q4/2024
                </div>
                    <span className='productCategories-productDescription'>
                    AgriJet 4000 jest ultrawydajnym urządzeniem do dezynfekcji powierzchni w obiektach wykorzystywanych do chowu zwierząt.Wykorzystuje efekt Coandy, który jest zjawiskiem fizycznym polegającym na tym, iż strumień gazu ma tendencję do przylegania do najbliższej powierzchni, przegrody lub ściany. Niewielki, swobodnie przepływający strumień powietrza przyspiesza dotychczas nieruchome cząstki powietrza w bezpośrednim otoczeniu tworząc wokół siebie strefę niskiego ciśnienia. Powstałe w ten sposób siły wypadkowe stabilizują strumień w linii prostej. Do strumienia zostanie przyłożona płaska powierzchnia i następuje wciągnięcie nieruchomych cząsteczek powietrza między strumień a powierzchnię przegrody. W praktyce zjawisko to powoduje ośmiokrotne wzmocnienie niewielkiego strumienia generowanego przez dwa wentylatory o łącznym przepływie 500 m3/h. Urządzenie wyposażono w świetlówki UV-C o łącznej mocy 2200 W. Przepływ na poziomie 4000 m3/h i dawka 12mJ/cm2 pozwala skutecznie ograniczyć namnażanie się wirusa np. ptasiej Grypy na fermach drobiu. AgriJet wykonano w całości ze stopów aluminium i stali nierdzewnej całkowicie odpornych na promieniowanie UV-C, wodę oraz amoniak występujący w obiektach hodowlanych. Urządzenie wyposażono w automatyczny system usuwania kurzu z powierzchni przegród. Urządzenie można myć strumieniem wody pod ciśnieniem.
                    </span>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                                <ul className="productCategories-secondSectionList">
                                    <li>Zabija wirusy, bakterie i pleśń</li> 
                                    <li>Redukuje nieprzyjemne zapachy</li>
                                    <li>Poprawia dobrostan zwierząt poprzez ujemną jonizację powietrza</li>
                                    <li>Eliminuje niebezpieczne substancje lotne (amoniak, siarkowodór, tlenki azotu itp.)</li>
                                    <li>Wydajność 4000m3/h</li> 
                                    <li>Żywotność PCO 50 000h, łatwa wymiana lampy UVC (co 9000h)</li>
                                    <li>ikroprocesorowy kontroler z zaawansowaną kontrolą czasu pracy, temperatury i poziomu przepływu modułów oczyszczania</li>
                                    <li>ODPORNOŚĆ NA KURZ - Brak wentylatora! Bez filtrów!</li>
                                    <li>100% bezpieczeństwa: nie produkuje ozonu, czujniki obecności</li>
                                </ul>
                        </div>
                        <ProductSlider productImages={[AgriJet4000Img, AgriJet4000BackImg, AgriJetGImg, AgriJetHImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>  
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Wymiary (całego systemu):</p>
                                <p>H: 1.0m</p>
                                <p>W: 1.5m</p>
                                <p>D: 2.5m</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Waga</p>
                                <img className='productParametersIcon' src={weightIconImg} alt='icon'/>
                                <p>Około 60kg</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Napięcie znamionowe: 220-240V</p>
                                <p>Częstotliwość: 50-60Hz</p>
                                <p>Moc: 3500W</p>
                                <p>IP69: IEC 60529</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UVIconImg} alt='icon'/>
                                <p>Minimalne natężenie promieniowania: 32mJ/cm2</p>
                                <p>Lampy: 40 x Philips TLUVC55W</p>
                                <p>Sterownik: 20 x HF-P 254/255 TL5 HO/PLL III</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Technologie dezynfekcji:</p>
                                <ul>
                                <li>Powierzchniowa dezynfekcja UVC</li>
                                <li>Dezynfekcja PCO</li>
                                </ul>
                                <p>Temperatura pracy/przechowywania: 10°C - 50°C / -25°C - 60°C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={UVC_IconImg} alt='icon'/>
                                <img className='productParametersIcon' src={MinusIconImg} alt='icon'/>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                        </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default AgriJet4000;