import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import UVCChamberSImg from "../../graphics/products/UVC-Chamber-S.jpg";
import UVCChamberSBackImg from "../../graphics/products/UVC-Chamber-SBack.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIconImg from  '../../graphics/icons/weightIcon.jpg';
import UVC_IconImg from  '../../graphics/icons/UVC_icon.jpg';
import MinusIconImg from  '../../graphics/icons/minusIcon.png';
import ProductSlider from "../../components_en/ProductSlider";


function UVC_ChamberS () {
    return(
        <>
            <Header/>
            <TitleSection h1={'UVC Chamber S'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
            <span className='productCategories-productDescription'>
            An UV-C chamber is a disinfection device that utilizes UV-C light for disinfecting clothing and other everyday items. The UV-C technology used not only provides powerful disinfection capabilities against pathogens but also eliminates the emission of harmful ozone, which can pose a potential health risk. The disinfection process is environmentally friendly and leaves no traces behind.
            The device is equipped with a PCO (photocatalytic) module that reduces unpleasant odors on disinfected surfaces. An additional advantage is the use of a customizable scent dispenser.
            The device utilizes state-of-the-art lamps with a total power of 120W, generating strong bactericidal radiation. This radiation permanently impairs the ability of viruses and bacteria to multiply on disinfected surfaces. In just a few moments, it safely eliminates harmful pathogens such as viruses, bacteria, and fungi, including SARS-CoV-2 and the Influenza virus.
            The entire body of the device is made of stainless steel. The chamber interior is constructed from highly polished aluminum, allowing for the reflection of over 95% of the light emitted by fluorescent lamps. This maximizes exposure to UV-C radiation, ensuring effective and rapid disinfection.
            Operating the device is straightforward and involves turning on the power and setting the cycle time. After opening the door, the user places items on the designated tray, closes the door, and sets the appropriate time by inserting the required amount of coins into the coin slot.
            The UV-C chamber is not only exceptionally safe in enclosed spaces but also equipped with numerous built-in safeguards that minimize the risk of accidental exposure to UV-C radiation, including lamp covers that protect against accidental damage.
            </span>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Kills viruses, bacteria and mould (COVID-19, ASF, Avian Influenza in seconds)</li>
                                <li>Surface disinfection (phones, tools, papers, etc.)</li>
                                <li>Reduces unpleasant odours (clothes, etc.)</li>
                                <li>Eliminates hazardous volatile substances (formaldehydes, ammonia, nitrogen oxides, etc.)</li>
                                <li>High UVC irradiance</li>
                                <li>Microprocessor controller with advanced control of purification modules working time, temperature and flow level</li>
                                <li>High output Philips UVC lamps</li>
                                <li>Eco design – robust design, almost eliminated plastics</li>
                                <li>100% safety: No Ozone produced, Double opening lock</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[UVCChamberSImg, UVCChamberSBackImg]}/>
                </div>
                <div className='productCategories-productParameters'>  
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Dimensions:</p>
                            <p>H: 900mm</p>
                            <p>W: 1020mm</p>
                            <p>D: 800mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Weight</p>
                            <img className='productParametersIcon' src={weightIconImg} alt='icon'/>
                            <p>Approx. 40kg</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Voltage: 220-240V</p>
                            <p>Frequency: 50-60Hz</p>
                            <p>Power: 130W</p>
                            <p>IP20: IEC 60529</p>
                            <p>Minimum irradiance: {'>'} 250 mJ / cm2</p>
                            <p>Lamps: 2 x Philips TUV PL-L 60W/4P HO</p>
                            <p>Driver: IUP-2S60-M4LD</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Working / storage temperature: 18°C - 40°C / -25°C - 60°C</p>
                            <p>Disinfection technologies:</p>
                            <ul>
                                <li>UVC surface disinfection</li>
                                <li>PCO disinfection (optional)</li>
                            </ul>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={UVC_IconImg} alt='icon'/>
                            <img className='productParametersIcon' src={MinusIconImg} alt='icon'/>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                    </div>
                    </div>
        </div>
        <Footer/>
        </>
    );
}

export default UVC_ChamberS;