import React from "react";
import Header from "../../components_en/Header";
import Footer from "../../components_en/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';

import plum48VWhiteImg from '../../graphics/products/plum-48v-white.jpg';
import ProductSlider from "../../components_en/ProductSlider";


function Plum48VWhite () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Plum 48V White'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                <h3>Suitable for any type of breeding</h3>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>White lamp with smooth dimming control</li>
                                <li>Possibility to set the brightness intensity from 0 to 100%</li>
                                <li>High degree of dust and water resistance</li>
                                <li>Cable glands for quick installation without interfering with the interior of the lamp</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[plum48VWhiteImg]}/>
                </div>
                <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Dimensions:</p>
                            <p>H: 117mm</p>
                            <p>W: 125mm</p>
                            <p>D: 183mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                            <p>Weight: 314g</p>
                            <p>DIMMABLE</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Voltage: 48V DC</p>
                            <p>Power: 13W</p>
                            <p>IP69 (IEC 60529)</p>
                            <p>LIGHT SOURCE: LED 50khrs</p>
                            <p>LIGHT OUTPUT: 1400 lm, White 3000K</p>
                            <p>CRI: 80</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Standards:</p>
                            <p>Safety EN 60598-1, EN 62471</p>
                            <p>Lamp is safety compliance with external regulation IEC 62560</p>
                            <p>Lamp is safe in normal application -10 to 40˚C </p>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default Plum48VWhite;