import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';
import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import plum230VWhiteImg from '../../graphics/products/plum-230v-white.jpg';
import ProductSlider from "../../components_en/ProductSlider";


function Plum230VWhiteBlue () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Plum 230V Digital White + Blue - Plum biało-niebieski'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                <h3>Nadaje się do każdego rodzaju hodowli</h3>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                            <li>Biało-niebieska lampa z płynną regulacją jasności</li>
                            <li>Możliwość ustawienia intensywności jasności od 0 do 100%</li>
                            <li>Wysoki stopień odporności na kurz i wodę</li>
                            <li>Przewody zaciskowe umożliwiające szybki montaż bez ingerencji w wnętrze lampy</li>
                            <li>Niebieskie światło nie jest widoczne dla ptaków, dzięki czemu mogą one spokojnie odpoczywać, a pracownicy mogą w tym czasie porządkować kurniki bez straszenia lub stresowania drobiu. Oprócz udowodnionego działania uspokajającego, niebieskie światło sprzyja również przyrostowi masy ciała u kurcząt.</li>
                        </ul>
                    </div>
                    <ProductSlider productImages={[plum230VWhiteImg]}/>
                </div>
                <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Wymiary:</p>
                            <p>H: 117mm</p>
                            <p>W: 125mm</p>
                            <p>D: 183mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                            <p>Waga: 314g</p>
                            <p>Ściemnialna</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Napięcie znamionowe: 230V 50/60Hz</p>
                            <p>Moc: 12W</p>
                            <p>IP69 (IEC 60529)</p>
                            <p>Żywotność źródła świata: LED 50khrs</p>
                            <p>Parametry świetlne: 1080lm biały, 45lm niebieski, biały 3000K</p>
                            <p>White 3000K</p>
                            <p>Niebieski: 450nm</p>
                            <p>Kąt rozsyłu 200°</p>
                            <p>CRI: 80</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                        <p>Standardy:</p>
                        <p>Bezpieczeństwo EN 60598-1, EN 62471</p>
                        <p>Lampa spełnia wymogi bezpieczeństwa zgodnie z przepisami normy IEC 62560</p>
                        <p>Lampa spełnia wymogi bezpieczeństwa w zakresie temperatur pracy: -10 do 40˚C </p>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default Plum230VWhiteBlue;