import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import noiseIconImg from '../../graphics/icons/noiseIcon.png';
import UV_IconImg from '../../graphics/icons/UV_icon.gif';
import RemoteControllerIconImg from '../../graphics/icons/RemoteControllerIcon.png';
import filterIconImg from '../../graphics/icons/filterIcon.jpg';
import UVC_IconImg from  '../../graphics/icons/UVC_icon.jpg';
import MinusIconImg from  '../../graphics/icons/minusIcon.png';

import ProductSlider from "../../components_en/ProductSlider";

import AirBarPanelImg from '../../graphics/products/AirPanel40.jpg';
import AirBarPanelDarkImg from '../../graphics/products/AirPanel40Dark.jpg';

import Img1Img from '../../graphics/products/ProductsImgs/Img1.jpg';
import Img2Img from '../../graphics/products/ProductsImgs/Img2.jpg';
import Img3Img from '../../graphics/products/ProductsImgs/Img3.jpg';

function AirPanel40 () {
    return(
        <>
            <Header/>
            <TitleSection h1={'AirPanel®40'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
                <div className='productCategories-productPageContainer'>
                <div className="productCategories-productCategoriesBigBoxTop">
                <img style={{height: "250px", marginTop: "20px"}} src={Img1Img} />
                <img style={{height: "250px", marginTop: "20px"}} src={Img2Img} />
                <img style={{height: "250px", marginTop: "20px"}} src={Img3Img} />
                </div>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Zabija wirusy, bakterie i pleśń</li> 
                                <li>Redukuje nieprzyjemne zapachy</li>
                                <li>Poprawia samopoczucie poprzez ujemną jonizację powietrza</li> 
                                <li>Eliminuje niebezpieczne substancje lotne (formaldehydy, amoniak, tlenki azotu itp.)</li> 
                                <li>Wydajność 100m3/h Nadaje się do biur o powierzchni do 20m2</li> 
                                <li>3 tryby: cichy, normalny, boost </li> 
                                <li>Mały, nowoczesny, sześcienny design pasujący do każdego miejsca</li> 
                            <   li>Sterownik mikroprocesorowy z zaawansowaną kontrolą czasu pracy modułów oczyszczających, temperatury i poziomu przepływu</li>
                                <li>Zdalnie sterowany </li> 
                                <li>Żywotność 50 000 godzin – brak konserwacji przez 17 lat (przy użytkowaniu 8 godzin dziennie</li>
                                <li>Eco design – brak lamp rtęciowych, niemal wyeliminowanie tworzyw sztucznych</li>
                                <li>100% bezpieczeństwa: nie wytwarza ozonu, zamknięty reaktor UVC z certyfikatem foto-bio-bezpieczeństwa</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[AirBarPanelDarkImg, AirBarPanelImg]}/>
                </div>
                <div className='productCategories-productParameters'>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Wymiary:</p>
                            <p>H: 595mm</p>
                            <p>W: 595mm</p>
                            <p>D: 57mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                            <p>Waga: 2.9kg</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Napięcie znamionowe: 220-240V</p>
                            <p>Częstotliwość: 50-60Hz</p>
                            <p>Moc: 60W</p>
                            <p>IP20 (IEC 60529)</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={noiseIconImg} alt='icon'/>
                            <p>Poziom hałasu: {'<'} 39dB </p>
                            <p>Przepływ powietrza: 3 tryby, do 40m3/h</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={UV_IconImg} alt='icon'/>
                            <p>Źródło UVC: UVC LED Module 50khrs</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={RemoteControllerIconImg} alt='icon'/>
                            <p>Kontroler MCU: żywotność, wydajność, przepływ powietrza</p>
                            <p>Zdalnie sterowany</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                                <p>Technologie dezynfekcji:</p>
                                <ul>
                                    <li>filtr PP lub HEPA (do wyboru)</li>
                                    <li>dezynfekcja UVC</li>
                                    <li>dezynfekcja PCO</li>
                                </ul>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={filterIconImg} alt='icon'/>
                            <img className='productParametersIcon' src={UVC_IconImg} alt='icon'/>
                            <img className='productParametersIcon' src={MinusIconImg} alt='icon'/>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default AirPanel40;