import React from "react";
import Header from "../components_en/Header";
import Footer from "../components_en/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import AirBarSR100VImg from '../graphics/products/AirBar100V.jpg';
import AirBarSR100HImg from '../graphics/products/AirBarSR100H.jpg';
import AirBarDR250HImg from '../graphics/products/AirBarSR100H.jpg';
import AirBarDR250BImg from '../graphics/products/DR250B.jpg';
import AirBarDR250VImg from '../graphics/products/AirBarDR250VBlack.png'
import AirBarSR40Img from '../graphics/products/AirPanel40.jpg';
import AirPaintImg from '../graphics/products/AirPaint40.png';
import AirPanelImg from '../graphics/products/AirPanel40-80-dark.png';



import ProductBox from "../components_en/ProductBox";


function HomeHotelClinicGym () {
    return(
        <>
        <Header/>
        <TitleSection h1={'Home/Hotel/Clinic/Gym'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <span style={{width: "70%"}}className='productCategories-productGroupDescription'>
            Our devices use based on the airflow exposed to UV-C radiation with a wavelength of 254 nm, reducing the population of microorganisms in the purified air. During operation, people can be in the immediate vicinity of the device, as it does not emit harmful UV-C radiation or ozone. The device is equipped with a reflector that ensures a 95% reflection of UV-C light, compared to standard solutions that achieve only 70%. 
Depending on the type, the radiation source is fluorescent lamps or LED UV-C lamps.
Two highly efficient and quiet fans provide the airflow. Depending on the equipment, the device can disinfect from 40 m3/h to 250 m3/h of air. Optionally, the UV-C air purifier can be equipped with a HEPA filter and a PCO module.
            </span>
            <div id='productCategories-productsGrid'>
            <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-sr100v'} productImg={AirBarSR100VImg} productTitle='AirBar®' productSubtitle='SR100V' productCategory='productCategories-UVC'/>
            <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airpanel-40'} productImg={AirBarSR40Img} productTitle='AirPanel®40' productSubtitle='' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airpanel-80'} productImg={AirPanelImg} productTitle='AirPanel®80' productCategory='productCategories-UVC'/>  
                <ProductBox productPageHref={'/products/uvc-air-desinfection/led-purifers/airpaint-40'} productImg={AirPaintImg} productTitle='AirPaint®40' productCategory='productCategories-UVC'/>  
                <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-sr100h'} productImg={AirBarSR100HImg} productTitle='AirBar®'  productSubtitle='SR100H' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-dr250b'} productImg={AirBarDR250BImg} productTitle='AirBar®'  productSubtitle='DR250B' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-dr250v'} productImg={AirBarDR250VImg} productTitle='AirBar®'  productSubtitle='DR250V' productCategory='productCategories-UVC'/>
                <ProductBox productPageHref={'/products/uvc-air-desinfection/tube-purifers/airbar-dr250h'} productImg={AirBarDR250HImg} productTitle='AirBar®'  productSubtitle='DR250H' productCategory='productCategories-UVC'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default HomeHotelClinicGym;