import '../styles/App.css';
import '../styles/Company.css';
import Header from "../components_pl/Header";
import logoCircle from '../graphics/circle.png';
import Footer from '../components_pl/Footer';
import logoDark from '../graphics/logo-next-lighting-dark.png';
import Line from '../components_en/Line';
import rndGraphics from '../graphics/r-d-team.jpg';
import manofacturingGraphics from '../graphics/manufacturing.jpg';
import certificationGraphics from '../graphics/certification.jpg';

function Company() {
  return (
    <>
        <Header />
        <>
          <div id="firstSection" style={{height: '650px'}}>
          <div className={'pageTitle'}>
              <h1 className='whiteText company-bigTitle'>NEXT LIGHTING</h1>
              <h3 className='whiteText company-bigTitle2'>Rozwój i produkcja</h3>
          </div>
          </div>
          <div id='circleBox'>
              <img id='middleCircle' src={logoCircle} alt="Next Lighting"/>
          </div>
        </>
        <div id='company-secondSection'>
          <div id='company-secondSectionCentered'>
            <div id='company-secondSectionLogoBox'>
              <img id='company-secondSectionLogo' src={logoDark} alt="Next Lighting" title="Next Lighting" />
            </div>
            <div id='company-secondSectionDescription'>
              <p className='company-secondSectionParagraph'>
              NEXT Lighting skupia się na rozwoju i produkcji dedykowanych inteligentnych systemów oświetleniowych oraz rozwiązań UV dla klientów. Nasza wiedza w obszarach oświetlenia, technologii UV oraz Internetu Rzeczy (IoT) sprawia, że jesteśmy wiarygodnym i elastycznym partnerem w systemie OEM i ODM.
              </p>
              <p className='company-secondSectionParagraph'>
              Od projektowania koncepcji, prototypowania, uprzemysłowienia, po masową produkcję oraz  zapewnienie jakości i wsparcie logistyczne. Obejmujemy cały łańcuch usług i pomagamy w przekształceniu pomysłu w produkt gotowy do rynku. Znajdujemy się w Toruniu - w centralnej części Polski, co znacznie ułatwia kontakt z klientem na terenie całego kraju. Nasz zespół liczy prawie 20 inżynierów wysoko wykwalifikowanych w projektowaniu urządzeń oświetleniowych i UV.
              </p>
            </div>
          </div>
        </div>
        <div id='company-thirdSection'>
            <div id='company-thirdSectionCentered'>
                <h2>Dlaczego my?</h2>
                <Line color1='#081424' color2='#69dbe1' color3='#eeeeee' lineWidth='200px' lineHeight='5px' />
                <div id='company-thirdSectionBoxContainer'>
                  <div className='company-thirdContainerBox'>
                    <img className='company-thirdSectionBoxImg' src={rndGraphics} alt='R&D'/>
                    <h3>Zespół R&D</h3>
                    <Line color1='#081424' color2='#69dbe1' color3='#eeeeee' lineWidth='100px' lineHeight='2px' />
                    <p className='company-thirdContainerBoxText'>Wyobraź sobie zespół pełen energii, gdzie każdy może wykorzystać swoją pasję do tworzenia nie tylko finalnego produktu, ale prawdziwego dzieła sztuki, aby uszczęśliwić naszego klienta.</p>
                    <p className='company-thirdContainerBoxText'>Przez ostatnie 20 lat nasi inżynierowie rozwijali nowe produkty i kierowali projektami w firmach takich jak Philips, Whirlpool, Jabil, Signify, Indesit i Apator.</p>
                  </div>
                  <div className='company-thirdContainerBox'>
                    <img className='company-thirdSectionBoxImg' src={certificationGraphics} alt='Certification'/>
                    <h3>Certyfikacja</h3>
                    <Line color1='#081424' color2='#69dbe1' color3='#eeeeee' lineWidth='100px' lineHeight='2px' />
                    <p className='company-thirdContainerBoxText'>Szybki proces walidacji i czas wprowadzenia na rynek dzięki naszym laboratoriom:</p>
                    <ul className="company-thirdSectionBoxList">
                      <li>Laboratorium fotometryczne</li>
                      <li>Bezpieczeństwo fotobiologiczne</li>
                      <li>Laboratoria elektryczne i EMI</li>
                      <li>Laboratorium mechaniczne i bezpieczeństwa</li>
                    </ul>
                  </div>
                  <div className='company-thirdContainerBox'>
                    <img className='company-thirdSectionBoxImg' src={manofacturingGraphics} alt='Manofacturing'/>
                    <h3>Produkcja</h3>
                    <Line color1='#081424' color2='#69dbe1' color3='#eeeeee' lineWidth='100px' lineHeight='2px' />
                    <p className='company-thirdContainerBoxText'>Trzy elementy sprawiają, że nasza fabryka wyróżnia się pod względem jakości, niezawodności i kosztów:</p>
                    <ul className="company-thirdSectionBoxList">
                      <li>LEAN MANUFACTURING - funkcjonowanie skoncentrowane na eliminacji marnotractwa</li>
                      <li>INDUSTRY 4.0 - inteligentna i elastyczna automatyzacja oraz systemy</li>
                      <li>INTEGRACJA PIONOWA - montaż SMT, THT, oraz strategiczni partnerzy w dziedzinie metalu i optyki</li>
                    </ul>
                  </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  );
}

export default Company;
