import {Routes, Route} from 'react-router-dom';
import Company_en from './pages_en/Company';
import Odm_en from './pages_en/Odm';
import Products_en from './pages_en/Products';
import AnimalCentricLighting_en from './pages_en/AnimalCentricLighting';
import LongPro_en from './pages_en/LongPro';
import Plum_en from './pages_en/Plum';
import SunPro_en from './pages_en/products/SunPro';
import Pro15_en from './pages_en/products/Pro15';
import Pro15Plus_en from './pages_en/products/Pro15Plus';
import Xara_en from './pages_en/products/Xara';
import ProBox_en from './pages_en/products/ProBox';
import UVC_ChamberS_en from './pages_en/products/UVC_ChamberS';
import AgriJet4000_en from './pages_en/products/AgriJet4000';
import LongProWhite_en from './pages_en/products/LongProWhite';
import LongProWhiteRedDigital_en from './pages_en/products/LongProWhiteRedDigital';
import Plum48VWhite_en from './pages_en/products/Plum48VWhite';
import Plum110VWhite_en from './pages_en/products/Plum110VWhite';
import Plum230VWhite_en from './pages_en/products/Plum230VWhite';
import Plum48VWhiteBlue_en from './pages_en/products/Plum48VWhiteBlue';
import Plum230VWhiteBlue_en from './pages_en/products/Plum230VWhiteBlue';
import Plum48VWhiteRed_en from './pages_en/products/Plum48VWhiteRed';
import Plum230VWhiteRed_en from './pages_en/products/Plum230VWhiteRed';

import UVCAirDisinfection_en from './pages_en/UVCAirDisinfection';
import AirPanel40_en from './pages_en/products/AirPanel40';
import AirBarSR100V_en from './pages_en/products/AirBarSR100V';
import AirBarSR100H_en from './pages_en/products/AirBarSR100H';
import AirBarDR250H_en from './pages_en/products/AirBarDR250H';
import AirBarDR250B_en from './pages_en/products/AirBarDR250B';
import AirBarDR250V_en from './pages_en/products/AirBarDR250V';
import AirBar40Transport_en from './pages_en/products/AirBar40Transport';
import AirPanel80_en from './pages_en/products/AirPanel80';
import AirPaint40_en from './pages_en/products/AirPaint40';
import UVAInsectTraps_en from './pages_en/UVAInsectTraps';
import T810W_en from './pages_en/products/T810W';
import T89W_en from './pages_en/products/T89W';
import InsectTrap_en from './pages_en/products/InsectTrap';
import UVTubes_en from './pages_en/UVTubes';
import Contact_en from './pages_en/Contact';
import PrivacyPolicy_en from './pages_en/PrivacyPolicy';
import Competences_en from './pages_en/Competences';
import LongProWhiteBlueDigital_en from './pages_en/products/LongProWhiteBlueDigital';
import TubePurifiers_en from './pages_en/TubePurifiers';
import LEDPurifiers_en from './pages_en/LEDPurifiers';
import T813W_en from './pages_en/products/T813W';
import T89WFlyWingsSimulation_en from './pages_en/products/T89WFlyWingsSimulation';
import HumanCentricLighting_en from './pages_en/HumanCentricLighting';
import SkyPanel_en from './pages_en/products/SkyPanel';
import LongProHuman_en from './pages_en/products/LongProHuman';
import HomeHotelClinicGym_en from './pages_en/HomeHotelClinicGym';
import AgricultureAndIndustry_en from './pages_en/AgricultureAndIndustry';


import Company_pl from './pages_pl/Company';
import Odm_pl from './pages_pl/Odm';
import Products_pl from './pages_pl/Products';
import AnimalCentricLighting_pl from './pages_pl/AnimalCentricLighting';
import LongPro_pl from './pages_pl/LongPro';
import Plum_pl from './pages_pl/Plum';
import SunPro_pl from './pages_pl/products/SunPro';
import Pro15_pl from './pages_pl/products/Pro15';
import Pro15Plus_pl from './pages_pl/products/Pro15Plus';
import Xara_pl from './pages_pl/products/Xara';
import ProBox_pl from './pages_pl/products/ProBox';
import UVC_ChamberS_pl from './pages_pl/products/UVC_ChamberS';
import AgriJet4000_pl from './pages_pl/products/AgriJet4000';
import LongProWhite_pl from './pages_pl/products/LongProWhite';
import LongProWhiteRedDigital_pl from './pages_pl/products/LongProWhiteRedDigital';
import Plum48VWhite_pl from './pages_pl/products/Plum48VWhite';
import Plum110VWhite_pl from './pages_pl/products/Plum110VWhite';
import Plum230VWhite_pl from './pages_pl/products/Plum230VWhite';
import Plum48VWhiteBlue_pl from './pages_pl/products/Plum48VWhiteBlue';
import Plum230VWhiteBlue_pl from './pages_pl/products/Plum230VWhiteBlue';
import Plum48VWhiteRed_pl from './pages_pl/products/Plum48VWhiteRed';
import Plum230VWhiteRed_pl from './pages_pl/products/Plum230VWhiteRed';

import UVCAirDisinfection_pl from './pages_pl/UVCAirDisinfection';
import AirPanel40_pl from './pages_pl/products/AirPanel40';
import AirBarSR100V_pl from './pages_pl/products/AirBarSR100V';
import AirBarSR100H_pl from './pages_pl/products/AirBarSR100H';
import AirBarDR250H_pl from './pages_pl/products/AirBarDR250H';
import AirBarDR250B_pl from './pages_pl/products/AirBarDR250B';
import AirBarDR250V_pl from './pages_pl/products/AirBarDR250V';
import AirBar40Transport_pl from './pages_pl/products/AirBar40Transport';
import AirPanel80_pl from './pages_pl/products/AirPanel80';
import AirPaint40_pl from './pages_pl/products/AirPaint40';
import UVAInsectTraps_pl from './pages_pl/UVAInsectTraps';
import T810W_pl from './pages_pl/products/T810W';
import T89W_pl from './pages_pl/products/T89W';
import InsectTrap_pl from './pages_pl/products/InsectTrap';
import UVTubes_pl from './pages_pl/UVTubes';
import Contact_pl from './pages_pl/Contact';
import PrivacyPolicy_pl from './pages_pl/PrivacyPolicy';
import Competences_pl from './pages_pl/Competences';
import LongProWhiteBlueDigital_pl from './pages_pl/products/LongProWhiteBlueDigital';
import TubePurifiers_pl from './pages_pl/TubePurifiers';
import LEDPurifiers_pl from './pages_pl/LEDPurifiers';
import T813W_pl from './pages_pl/products/T813W';
import T89WFlyWingsSimulation_pl from './pages_pl/products/T89WFlyWingsSimulation';
import HumanCentricLighting_pl from './pages_pl/HumanCentricLighting';
import SkyPanel_pl from './pages_pl/products/SkyPanel';
import LongProHuman_pl from './pages_pl/products/LongProHuman';
import HomeHotelClinicGym_pl from './pages_pl/HomeHotelClinicGym';
import AgricultureAndIndustry_pl from './pages_pl/AgricultureAndIndustry';
import T89WDRF_en from './pages_en/products/T89WDRF';
import T89WDRF_pl from './pages_pl/products/T89WDRF';


function App() {
  const getLanguage = () => {
      let language = localStorage.getItem('language');
      if (language==null)
        return "en";
      return language;
  }
  let language = getLanguage();
  switch(language)
  {
    case "en":
      return (
        <Routes>
          <Route path='' element={<Company_en/>} />
          <Route path='/services' element={<Odm_en/>} />
          <Route path='/products' element={<Products_en/>} />
          <Route path='/our-competences' element={<Competences_en />} />
          <Route path='/contact' element={<Contact_en />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy_en />} />
          <Route path='/products/animal-centric-lighting' element={<AnimalCentricLighting_en/>} />
  
          <Route path='/products/animal-centric-lighting/sunpro' element={<SunPro_en/>} />
          <Route path='/products/animal-centric-lighting/pro15' element={<Pro15_en/>} />
          <Route path='/products/animal-centric-lighting/pro15-plus' element={<Pro15Plus_en/>} />
          <Route path='/products/animal-centric-lighting/xara' element={<Xara_en/>} />
          <Route path='/products/animal-centric-lighting/probox' element={<ProBox_en/>} />
          <Route path='/products/animal-centric-lighting/uvc-chamber-s' element={<UVC_ChamberS_en />} />
          <Route path='/products/animal-centric-lighting/agri-jet-4000' element={<AgriJet4000_en />} />
  
          <Route path='/products/animal-centric-lighting/longpro-products' element={<LongPro_en/>} />
          <Route path='/products/animal-centric-lighting/longpro-products/longpro-white' element={<LongProWhite_en />} />
          <Route path='/products/animal-centric-lighting/longpro-products/longpro-white-red-digital' element={<LongProWhiteRedDigital_en />} />
          <Route path='/products/animal-centric-lighting/longpro-products/longpro-white-blue-digital' element={<LongProWhiteBlueDigital_en />} />
  
  
          <Route path='/products/human-centric-lighting' element={<HumanCentricLighting_en/>} />
          <Route path='/products/human-centric-lighting/sky-panel' element={<SkyPanel_en/>} />
          <Route path='/products/human-centric-lighting/longpro-hcl' element={<LongProHuman_en/>} />
  
          <Route path='/products/animal-centric-lighting/plum-products' element={<Plum_en/>} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-48v-white' element={<Plum48VWhite_en />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-230v-white' element={<Plum230VWhite_en />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-48v-white-blue' element={<Plum48VWhiteBlue_en />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-48v-white-red' element={<Plum48VWhiteRed_en />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-110v-white' element={<Plum110VWhite_en />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-230v-white-blue' element={<Plum230VWhiteBlue_en />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-230v-white-red' element={<Plum230VWhiteRed_en />} />
  
  
          <Route path='/products/uvc-air-desinfection' element={<UVCAirDisinfection_en />} />
          <Route path='/products/uvc-air-desinfection/agriculture-and-industry' element={<AgricultureAndIndustry_en />} />
          <Route path='/products/uvc-air-desinfection/home-hotel-clinic-gym' element={<HomeHotelClinicGym_en />} />
  
          <Route path='/products/uvc-air-desinfection/tube-purifers' element={<TubePurifiers_en />} />
          <Route path='/products/uvc-air-desinfection/led-purifers' element={<LEDPurifiers_en />} />
  
          <Route path='/products/uvc-air-desinfection/led-purifers/airpanel-40' element={<AirPanel40_en />} />
          
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-sr100v' element={<AirBarSR100V_en />} />
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-sr100h' element={<AirBarSR100H_en />} />
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-dr250h' element={<AirBarDR250H_en />} />
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-dr250b' element={<AirBarDR250B_en />} />
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-dr250v' element={<AirBarDR250V_en />} />
          
          <Route path='/products/uvc-air-desinfection/airbar-40-transport' element={<AirBar40Transport_en />} />
  
          <Route path='/products/uvc-air-desinfection/led-purifers/airpanel-80' element={<AirPanel80_en />} />
          <Route path='/products/uvc-air-desinfection/led-purifers/airpaint-40' element={<AirPaint40_en />} />
  
          <Route path='/products/uva-insect-traps' element={<UVAInsectTraps_en />} />
          <Route path='/products/uva-insect-traps/uva-tubes' element={<UVTubes_en />} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-10w' element={<T810W_en />} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-9w-dfr' element={<T89WDRF_en />} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-13w' element={<T813W_en/>} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-9w' element={<T89W_en />} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-9w-flywings-simulation' element={<T89WFlyWingsSimulation_en />} />
          <Route path='/products/uva-insect-traps/insect-trap' element={<InsectTrap_en />} />
          
        </Routes>
    );
    break;
    case "pl":
      return (
        <Routes>
          <Route path='' element={<Company_pl/>} />
          <Route path='/services' element={<Odm_pl/>} />
          <Route path='/products' element={<Products_pl/>} />
          <Route path='/our-competences' element={<Competences_pl />} />
          <Route path='/contact' element={<Contact_pl />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy_pl />} />
          <Route path='/products/animal-centric-lighting' element={<AnimalCentricLighting_pl/>} />
  
          <Route path='/products/animal-centric-lighting/sunpro' element={<SunPro_pl/>} />
          <Route path='/products/animal-centric-lighting/pro15' element={<Pro15_pl/>} />
          <Route path='/products/animal-centric-lighting/pro15-plus' element={<Pro15Plus_pl/>} />
          <Route path='/products/animal-centric-lighting/xara' element={<Xara_pl/>} />
          <Route path='/products/animal-centric-lighting/probox' element={<ProBox_pl/>} />
          <Route path='/products/animal-centric-lighting/uvc-chamber-s' element={<UVC_ChamberS_pl />} />
          <Route path='/products/animal-centric-lighting/agri-jet-4000' element={<AgriJet4000_pl />} />
  
          <Route path='/products/animal-centric-lighting/longpro-products' element={<LongPro_pl/>} />
          <Route path='/products/animal-centric-lighting/longpro-products/longpro-white' element={<LongProWhite_pl />} />
          <Route path='/products/animal-centric-lighting/longpro-products/longpro-white-red-digital' element={<LongProWhiteRedDigital_pl />} />
          <Route path='/products/animal-centric-lighting/longpro-products/longpro-white-blue-digital' element={<LongProWhiteBlueDigital_pl />} />
  
  
          <Route path='/products/human-centric-lighting' element={<HumanCentricLighting_pl/>} />
          <Route path='/products/human-centric-lighting/sky-panel' element={<SkyPanel_pl/>} />
          <Route path='/products/human-centric-lighting/longpro-hcl' element={<LongProHuman_pl/>} />
  
          <Route path='/products/animal-centric-lighting/plum-products' element={<Plum_pl/>} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-48v-white' element={<Plum48VWhite_pl />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-230v-white' element={<Plum230VWhite_pl />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-48v-white-blue' element={<Plum48VWhiteBlue_pl />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-48v-white-red' element={<Plum48VWhiteRed_pl />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-110v-white' element={<Plum110VWhite_pl />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-230v-white-blue' element={<Plum230VWhiteBlue_pl />} />
          <Route path='/products/animal-centric-lighting/plum-products/plum-230v-white-red' element={<Plum230VWhiteRed_pl />} />
  
  
          <Route path='/products/uvc-air-desinfection' element={<UVCAirDisinfection_pl />} />
          <Route path='/products/uvc-air-desinfection/agriculture-and-industry' element={<AgricultureAndIndustry_pl />} />
          <Route path='/products/uvc-air-desinfection/home-hotel-clinic-gym' element={<HomeHotelClinicGym_pl />} />
  
          <Route path='/products/uvc-air-desinfection/tube-purifers' element={<TubePurifiers_pl />} />
          <Route path='/products/uvc-air-desinfection/led-purifers' element={<LEDPurifiers_pl />} />
  
          <Route path='/products/uvc-air-desinfection/led-purifers/airpanel-40' element={<AirPanel40_pl />} />
          
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-sr100v' element={<AirBarSR100V_pl />} />
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-sr100h' element={<AirBarSR100H_pl />} />
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-dr250h' element={<AirBarDR250H_pl />} />
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-dr250b' element={<AirBarDR250B_pl />} />
          <Route path='/products/uvc-air-desinfection/tube-purifers/airbar-dr250v' element={<AirBarDR250V_pl />} />
          
          <Route path='/products/uvc-air-desinfection/airbar-40-transport' element={<AirBar40Transport_pl />} />
  
          <Route path='/products/uvc-air-desinfection/led-purifers/airpanel-80' element={<AirPanel80_pl />} />
          <Route path='/products/uvc-air-desinfection/led-purifers/airpaint-40' element={<AirPaint40_pl />} />
  
          <Route path='/products/uva-insect-traps' element={<UVAInsectTraps_pl />} />
          <Route path='/products/uva-insect-traps/uva-tubes' element={<UVTubes_pl />} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-10w' element={<T810W_pl />} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-13w' element={<T813W_pl/>} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-9w' element={<T89W_pl />} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-9w-flywings-simulation' element={<T89WFlyWingsSimulation_pl />} />
          <Route path='/products/uva-insect-traps/insect-trap' element={<InsectTrap_pl />} />
          <Route path='/products/uva-insect-traps/uva-tubes/t8-9w-dfr' element={<T89WDRF_pl />} />
        </Routes>
    );
      break;

  }
}

export default App;
