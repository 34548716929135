import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import xaraImg from "../../graphics/products/xaraE27bulb.jpg";
import xara_backImg from "../../graphics/products/xaraE27bulb_back.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';
import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import ProductSlider from "../../components_en/ProductSlider";



function Xara () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Xara'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <h3>Nadaje się do każdego rodzaju hodowli</h3>
                    <div className='productCategories-productDescription'>
                    To nasza jedyna żarówka zaprojektowana do pracy w bardzo wysokich temperaturach aż do +60 stopni Celsjusza. Może być stosowana w dowolnej oprawie, umożliwiając łatwą modernizację różnych instalacji oświetleniowych w różnych obiektach rolniczych. Xara charakteryzuje się ponadprzeciętną skutecznością świetlną, co bezpośrednio przekłada się na oszczędności energii. Żarówka emituje światło o temperaturze barwowej 2900K
                    </div>
                    <div className='productCategories-descriptionAndParameters'>
                        <ul className="productCategories-secondSectionList">
                                <li>6W żarówka z funkcją regulacji jasności</li>
                                <li>Efektywność 135lm/W</li>
                                <li>Odpowiada żarówce 60W</li>
                                <li>Emituje światło o temperaturze barwowej 2900K (ciepłe białe światło))</li>
                                <li>Najlepsza wydajność regulacji na rynku (bardzo płynna regulacja)</li>
                                <li>Brak migotania</li>
                                <li>Bardzo długa żywotność</li>
                            </ul>
                            <ProductSlider productImages={[xaraImg, xara_backImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Wymiary:</p>
                                <p>H: 120mm</p>
                                <p>W: 63mm</p>
                                <p>D: 63mm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Waga: 58g</p>
                                <p>Ściemnialna przez ucinanie fazy</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Napięcie znamionowe: 230V 50/60Hz</p>
                                <p>Moc: 6W</p>
                                <p>Żywotność źródła świata: LED 50 tys. godzin</p>
                                <p>Parametry świetlne: 820lm biały 2900K</p>
                                <p>Kąt rozsyłu 170°</p>
                                <p>CRI: {'>'} 80</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Standardy:</p>
                                <p>Bezpieczeństwo EN 60598-1, EN 62471</p>
                                <p>Żarówka spełnia wymogi bezpieczeństwa w zakresie temperatur pracy: -10 - 50˚C </p>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                            
                        </div>
                        </div>
        </div>
        <Footer/>
        </>
    );
}

export default Xara;