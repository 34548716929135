import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_pl/TitleSection";
import '../styles/App.css';
import '../styles/Products.css'

import animalCentricLightingImg from "../graphics/animal-centric-lighting.jpg";
import humanCentricLightingImg from '../graphics/human-centric-lighting.jpg';
import uvaInsectTrapsImg from '../graphics/uva-insect-traps.jpg';
import uvaAirDesinfectionImg from '../graphics/uvc-air-disinfection.jpg';
import warranty from "../graphics/icons/warranty.webp"

const moveToPage = (href) => {
    window.location.href = href;
}

function Products () {
    return (
        <>
            <Header/>
            <TitleSection h1={'PRODUKTY'} h2={''} height='280px'/>
            <div id="products-secondSection">
                <div className='products-secondSectionContainer'>
                    <a href="/products/animal-centric-lighting">
                    <div style={{backgroundImage: `url(${animalCentricLightingImg})`}} className='products-productCategoryBox'>
                        <h4 className='products-productCategoryBoxHeader'>OŚWIETLENIE DLA ROLNICTWA</h4>
                    </div>
                    </a>
                    <a href="/products/human-centric-lighting">
                    <div style={{backgroundImage: `url(${humanCentricLightingImg})`}} className='products-productCategoryBox'>
                        <h4 className='products-productCategoryBoxHeader'>HUMAN CENTRIC LIGHTING - Specjalistyczne oświetlenie zorientowane na potrzeby ludzi</h4>
                    </div>
                    </a>
                </div>
                <div className='products-secondSectionContainer'>
                    <a href="/products/uvc-air-desinfection">
                    <div style={{backgroundImage: `url(${uvaAirDesinfectionImg})`}} className='products-productCategoryBox'>
                        <h4 className='products-productCategoryBoxHeader'>DEZYNFEKCJA POWIETRZA UVC</h4>
                    </div>
                    </a>
                    <a href="/products/uva-insect-traps">
                    <div style={{backgroundImage: `url(${uvaInsectTrapsImg})`}} className='products-productCategoryBox'>
                        <h4 className='products-productCategoryBoxHeader'>LAMPY UVA i MUCHOPUŁAPKI</h4>
                    </div>
                    </a>
                </div>
                <h4><img style={{width: "90px"}}src={warranty}/>GWARANCJA na wszystkie nasze produkty wynosi 2 lata.</h4>
            </div>
            <Footer/>
        </>
    );
}

export default Products;