import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_en/Footer";
import ProductBox from "../components_en/ProductBox";
import TitleSection from "../components_pl/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import longProWhiteImg from '../graphics/products/long-pro-white.jpg';

function LongPro () {
    return(
        <>
        <Header/>
        <TitleSection h1={'LONGPRO'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <span className="productCategories-productGroupDescription">
            To innowacyjna lampa linearna LED sterowana cyfrowo, za pośrednictwem tylko przewodów zasilających, łączy w sobie tubę  dwutrzonkową z oprawą oświetleniową. Opracowanie 2 produktów w 1 umożliwia ultraszybki montaż lampy, bez konieczności jej rozbierania na części. Sprawę przyspiesza zewnętrzne, możliwe obustronne podłączenie lampy do sieci, co ma ogromne znaczenie w montażu i demontażu na dużych obiektach, gdzie oświetlenie instalowane jest na wysokich sufitach. Long Pro jest bezmigotliwa, co w znaczącym stopniu poprawia funkcjonowanie zwierząt zapewniając im well- beeing oraz uspokojenie. Zastosowanie nowoczesnych sterowników umożliwia kontrolę jasności adekwatną do potrzeb, dzięki czemu Long Pro można stosować dla każdego rodzaju hodowli zwierząt.
            </span>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/animal-centric-lighting/longpro-products/longpro-white'} productImg={longProWhiteImg} productTitle='LongPro White' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/longpro-products/longpro-white-red-digital'} productImg={longProWhiteImg} productTitle='LongPro White + Red Digital' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/longpro-products/longpro-white-blue-digital'} productImg={longProWhiteImg} productTitle='LongPro White + Blue Digital' productCategory='productCategories-lamps'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default LongPro;