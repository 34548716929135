import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import sunProImg from "../../graphics/products/sun-pro.jpg";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import ProductSlider from "../../components_en/ProductSlider";


function SunPro () {
    return(
        <>
            <Header/>
            <TitleSection h1={'SunPro'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
                    <span className='productCategories-productDescription'>
                    Kontroler SunPro umożliwia programowanie scen świetlnych, takich jak wschody i zachody słońca. Symulacje naturalnych cykli dobowych pozwalają stworzyć środowisko wystarczająco zbliżone do naturalnego w hodowli drobiu. Poprzez odpowiednie dostosowanie symulowanych wschodów i zachodów słońca możemy na przykład zwiększyć efektywność produkcji jaj w kurniku.
                    </span>
                    <div className='productCategories-descriptionAndParameters'>
                        <div className='productCategories-description'>
                            <p>
                            Kontroler posiada następujące funkcje:
                            </p>
                                <ul className="productCategories-secondSectionList">
                                    <li>Symulacja wschodów i zachodów słońca</li>
                                    <li>Programy oświetleniowe</li>
                                    <li>Zintegrowany zegar z wyświetlaczem numerycznym</li>
                                    <li>Możliwość ustawienia dwóch włączeń na dzień</li>
                                    <li>Możliwość sterowania trybem manualnym i automatycznym</li>
                                    <li>Elektronika sterowana mikrokontrolerem</li>
                                    <li>Wyjście analogowe umożliwia podłączenie do 200 regulowanych lamp (interfejs 1-10V) lub urządzeń regulacyjnych (0-10V)</li>
                                </ul>
                        </div>
                        <ProductSlider productImages={[sunProImg]}/>
                    </div>
                    <div className='productCategories-productParameters'>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                                <p>Wymiary (całego systemu):</p>
                                <p>H: 85mm</p>
                                <p>W: 105mm</p>
                                <p>D: 60mm</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Waga: 250g</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                                <p>Napięcie znamoionowe: 100-230V 50/60Hz</p>
                                <p>Pobór mocy: 6W</p>
                                <p>Temperatura pracy: -10C to 45C</p>
                            </div>
                            <div className='productCategories-productParametersBox'>
                                <p>Standardy:</p>
                                <p>Bezpieczeństwo EN 60598-1, EN 62471</p>
                                <p>EMC EN 55022</p>
                                <p>Spełnia wymogi bezpieczeństwa w zakresie temperatur pracy: -10 - 50˚C </p>
                                <p>IP20 (IEC 60529)</p>
                                <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                            </div>
                    </div>
                </div>
        </div>
        <Footer/>
        </>
    );
}

export default SunPro;