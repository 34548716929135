import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import longProWhiteImg from '../graphics/products/long-pro-white.jpg';
import xaraE27BulbImg from '../graphics/products/xaraE27bulb.jpg';
import sunProImg from '../graphics/products/sun-pro.jpg';
import pro15Img from '../graphics/products/pro15.png';
import proBoxImg from '../graphics/products/proBox.jpg';
import AgriJet4000Img from '../graphics/products/AgriJet4000.png';
import UVCChamberSImg from '../graphics/products/UVC-Chamber-S.jpg';
import plumImg from '../graphics/products/plum-48v-white.jpg';
import ProductBox from "../components_en/ProductBox";


function AnimalCentricLighting () {
    return(
        <>
        <Header/>
        <TitleSection h1={'OŚWIETLENIE DLA ROLNICTWA'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <div className='productCategories-textParagraphRowBox'>
                <p className='productCategories-textParagraph'>
                Zwierzęta bardzo reagują na rytm dobowy oraz barwę i intensywność światła. Nasze urządzenia pomagają im poczuć się jak w naturalnym środowisku. Jedynie Polsce roczna produkcja jaj wynosi ponad 9 miliardów. Nasze produkty pozwalają zmniejszyć tzw. zanieczyszczenie skorup jajek poprzez eliminację zacieniania w niepożądanych miejscach.
                </p>
                <p className='productCategories-textParagraph'>
                Jesteśmy inżynierami, projektantami i producentami zaawansowanych urządzeń do sterowania lampami jedno- lub dwukanałowymi. Posiadają one wbudowane interfejsy 0-10V, RS485, Bluetooth i USB. Projektujemy, konstruujemy i produkujemy lampy LED o wysokiej klasie odporności na wodę i kurz do IP69, z szerokim kątem rozsyłu światła oraz zaawansowaną elektroniką, która umożliwia regulację jasności w zakresie 0-100% w dwóch kanałach. Każdy kanał jest regulowany osobno i umożliwia mieszanie barw. Przy konstrukcji lamp współpracowaliśmy wielokrotnie z Politechniką Warszawską, która opracowywała profil lamp o wystarczająco szerokim rozkładzie światła.
                </p>
            </div>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/animal-centric-lighting/longpro-products'} productImg={longProWhiteImg} productTitle='LongPro' productCategory='productCategories-Agri' productCategoryText='Lampy linearne'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products'} productImg={plumImg} productTitle='Plum' productCategory='productCategories-Agri' productCategoryText='Lampy 360°'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/xara'} productImg={xaraE27BulbImg} productTitle='Xara E27 Bulb' productCategory='productCategories-Agri' productId='productCategories-xaraMiniature' productCategoryText='LED bulbs'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/sunpro'} productImg={sunProImg} productTitle='SunPro' productCategory='productCategories-Agri' productCategoryText='Sunlight simulator (SLS)'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/pro15'} productImg={pro15Img} productTitle='Pro15' productCategory='productCategories-Agri' productCategoryText='Dimmer'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/pro15-plus'} productImg={pro15Img} productTitle='Pro15 plus' productCategory='productCategories-Agri' productCategoryText='Dimmer with SLS'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/probox'} productImg={proBoxImg} productTitle='ProBox' productCategory='productCategories-Agri' productCategoryText='Box dimmer with SLS'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/uvc-chamber-s'} productImg={UVCChamberSImg} productTitle='UVC Chamber S' productCategory='productCategories-Agri' productCategoryText='Surface disinfection'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/agri-jet-4000'} productId='products-agrijetMiniature' productImg={AgriJet4000Img} productTitle='AgriJet®4000' productCategory='productCategories-Agri' productCategoryText='Air disinfection'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default AnimalCentricLighting;