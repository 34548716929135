import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';
import skyPanelImg from '../../graphics/products/skyPanel.jpg';


function SkyPanel () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Sky Panel'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className='productCategories-productPageContainer'>
            <p className='productCategories-textParagraph'>
            Jest to zawansowany technologicznie panel LED z funkcją dostrajania temperatury barwowej światła zgodnie z naturalnym rytem dobowym człowieka jak i z unikatową w skali światowej funkcją przestrzennego generowania efektu przepływu chmur na niebie. 
Pojedynczy panel o wymiarach 60x60 cm jest częścią większej powierzchni np. sufitu hali konferencyjnej, biura itd. Możliwe jest zamontowanie go np. w dowolnej konfiguracji np. 10x10 sztuk. Panele komunikują się ze sobą stając się spójną całością.  Istnieje możliwość modyfikacji wyświetlanych chmur, sterowania kierunkiem i natężeniem wiatru itd. 
Przebywając w zamkniętym pomieszczeniu stwarza wrażenie pracy na łonie natury. Łagodzi stres, poprawia nastrój i sprzyja pozytywnym relacjom międzyludzkim. 
</p>
            <img style={{maxWidth: "800px"}} src={skyPanelImg} />
            <h1 className="siteInDevelopment">Produkt jest w fazie rozwoju</h1>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default SkyPanel;