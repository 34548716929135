import React from "react";
import Header from "../../components_pl/Header";
import Footer from "../../components_pl/Footer";
import TitleSection from "../../components_en/TitleSection";
import pro15Img from "../../graphics/products/pro15.png";
import '../../styles/ProductCategories.css';
import '../../styles/App.css';

import dimensionsIconImg from '../../graphics/icons/dimensionsIcon.png';
import powerIconImg from '../../graphics/icons/powerIcon.jpg';
import CE_IconImg from '../../graphics/icons/CE_icon.png';
import weightIcon from '../../graphics/icons/weightIcon.jpg';
import ProductSlider from "../../components_en/ProductSlider";


function Pro15Plus () {
    return(
        <>
            <Header/>
            <TitleSection h1={'Pro15 plus'} h2={''} height='300px'/>
            <div className='productCategories-secondSection'>
            <div className="nonAvailable">
                Dostępny Q4/2024
            </div>
            <div className='productCategories-productPageContainer'>
                <span className='productCategories-productDescription'>
                Pro15 Plus to udoskonalona wersja modelu PRO-15, oferująca dodatkowe funkcje, takie jak wbudowany zegar 24-godzinny i możliwość sterowania funkcją SLS (Symulacja Wschodu i Zachodu Słońca) przy użyciu wbudowanego zegara.
                <br></br><br></br>
                Regulacja mocy odbywa się poprzez przycinanie fazy, znane również jako tzw. trailing edge. Model PRO-15 został zaprojektowany do obsługi obciążeń rezystancyjnych i pojemnościowych. Pozwala na ręczną i automatyczną kontrolę oświetlenia za pomocą sygnału 0-10V, a nawet poprzez podłączenie pojedynczego przełącznika. Istnieje możliwość tworzenia pakietów przyciemniania w celu zwiększenia całkowitej zdolności energetycznej. Przyciemniacz obsługuje różne źródła światła podlegające przyciemnianiu, takie jak żarówki żarowe, żarówki halogenowe 230V AC, żarówki halogenowe o niskim napięciu zasilane przez transformatory elektroniczne, świetlówki energooszczędne CFL i CCFL, oraz żarówki LED. Instalacja przyciemniacza jest łatwa i szybka, a jego obsługa jest intuicyjna w 100%. Przychodzi w obudowie montowanej na szynie TH 35mm, a jego maksymalna zdolność obciążeniowa wynosi 1500W. Cyfrowy wyświetlacz dostarcza informacji o jego stanie, funkcjach bezpieczeństwa, a funkcje można programować za pomocą przycisków. Posiada również wbudowane zabezpieczenia elektroniczne przed przeciążeniem, zwarciami, przegrzewaniem i skokami napięcia w sieci. Oprogramowanie umożliwia symulację wschodu i zachodu słońca, znane jako SLS (Symulacja Wschodu i Zachodu Słońca).
                </span>
                <div className='productCategories-descriptionAndParameters'>
                    <div className='productCategories-description'>
                            <ul className="productCategories-secondSectionList">
                                <li>Tranzystorowy regulator oświetleniowy, maksymalna zdolność obciążeniowa 1500W - Regulacja ręczna lub za pomocą zewnętrznego kontrolera 0-10V (PC, PLC, SLS1+, itp.)</li>
                                <li>Możliwość równoległego podłączania w celu zwiększenia całkowitej mocy</li>
                                <li>Optyczne i akustyczne sygnalizowanie błędów - Wyjście alarmowe - Dodatkowa funkcjonalność: funkcja cyklu światła (wschód i zachód słońca)</li>
                                <li>Łatwa, intuicyjna obsługa</li>
                                <li>Metoda montażu: szyna DIN-35 rail</li>
                            <p>Reguluje różne rodzaje źródeł światła:</p>
                            <ul className="productCategories-secondSectionList">
                                <li>Żarówki żarowe i halogenowe 230V AC</li>
                                <li>Żarówki halogenowe o niskim napięciu zasilane przez transformator elektroniczny </li>
                                <li>Żarówki energooszczędne CFL i CCFL</li>
                                <li>Żarówki LED podlegające przyciemnianiu</li>
                            </ul>
                            <li>Informacje o stanie i błędach na wyświetlaczu LED</li>
                            <li>Wyjście alarmowe</li>
                            <li>Wbudowana funkcja SLS umożliwia ustawienie symulacji wschodu i zachodu słońca</li>
                            <li>Łatwa, intuicyjna obsługa z możliwością własnej konfiguracji</li>
                            </ul>
                    </div>
                    <ProductSlider productImages={[pro15Img]}/>
                </div>
                <div className='productCategories-productParameters'>
                    <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={dimensionsIconImg} alt='icon'/>
                            <p>Wymiary (całego systemu):</p>
                            <p>H: 85mm</p>
                            <p>W: 105mm</p>
                            <p>D: 60mm</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                                <img className='productParametersIcon' src={weightIcon} alt='icon'/>
                                <p>Waga: 280g</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <img className='productParametersIcon' src={powerIconImg} alt='icon'/>
                            <p>Napięcie znamionowe: 230V 50/60Hz</p>
                            <p>Maksymalna moc obciążenia: 1500VA max</p>
                            <p>Metoda montażu: szyna DIN-35 rail</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                                <p>Wbudowane zabezpieczenia:</p>
                                <p>- Zabezpieczenie przed przeciążeniem</p>
                                <p>- Zabezpieczenie przed zwraciem</p>
                                <p>- Zabezpieczenie przed przegrzaniem</p>
                                <p>- Ochrona przed wysokim napięciem</p>
                        </div>
                        <div className='productCategories-productParametersBox'>
                            <p>Standardy:</p>
                            <p>Bezpieczeństwo EN 60598-1, EN 62471</p>
                            <p>EMC EN 55022</p>
                            <p>Spełnia wymogi bezpieczeństwa w zakresie temperatur pracy: -10 - 45˚</p>
                            <p>IP20 (IEC 60529)</p>
                            <img className='productParametersIcon' src={CE_IconImg} alt='icon'/>
                        </div>
                    </div>
                    </div>
        </div>
        <Footer/>
        </>
    );
}

export default Pro15Plus;