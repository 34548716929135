import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import TitleSection from "../components_en/TitleSection";
import '../styles/App.css';
import '../styles/Odm.css';
import '../styles/Competences.css';

import icon1 from "../graphics/icons/icon-01-start.png";
import icon2 from "../graphics/icons/icon-02-next.png";
import icon3 from "../graphics/icons/icon-03-next.png";
import icon4 from "../graphics/icons/icon-04-next.png";
import icon5 from "../graphics/icons/icon-05-next.png";
import icon6 from "../graphics/icons/icon-06-next.png";

import competences01Img from "../graphics/competences-01.jpg";
import competences02Img from "../graphics/competences-02.jpg";

function Competences () {
        return(
            <>
                <Header />
                    <TitleSection h1={'NASZE KOMPETENCJE'} h2={''} height='280px'/>
                    <div id='competences-secondSection'>
                            <div id='competences-secondSectionCenteredBox'>
                                <div className='competences-cards'>
                                    <img className='competences-img' src={competences01Img} alt="Image"></img> 
                                    <span className='competences-text'>
                                    Nasz zespół badawczo-rozwojowy opracował koncepcje wielu produktów oświetleniowych i UV. Posiadamy ekspertów z zakresu  <span className='boldText'>mikrokontrolerów, programistów, energoelektroniki, optyki, metali, mechaniki</span>, a także pomiarów i<span className='boldText'>walidacji</span>. 
                                    Nad jakością badań i rozwoju czuwa  <span className='boldText'>
                                    DFSS Black Belt</span> 
                                    </span>
                                </div>
                                <div className='competences-cards'>
                                    <img className='competences-img' src={competences02Img} alt="Image"></img> 
                                    <span className='competences-text'>
                                    Doświadczenie w industrializacji z wykorzystaniem narzędzi takich jak <span className='boldText'>Design For Six Sigma, DFMA czy QFD</span>. Reżim ESD w całej hali produkcyjnej. Ponad 20 lat doświadczenia w inżynierii przemysłowej i produkcji <span className='boldText'>LEAN</span> z wykorzystaniem <span className='boldText'>VSM, Yamazumi, SMED, One Piece Flow or Cell Concept. </span> 
                                     Prawie 30 lat doświadczenia w łańcuchu dostaw x-region.
                                    </span>
                                </div>
                            </div>
                    </div>
                    <div id='competences-thirdSection'>
                        <div className='competences-thirdSectionTableBox'>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Koncepcja</span>
                                <img className='competences-thirdSectionTableIcon' src={icon1} alt='Concept design'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Development Rozwój</span>
                                <img className='competences-thirdSectionTableIcon' src={icon2} alt='Development'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Prototypowanie</span>
                                <img className='competences-thirdSectionTableIcon' src={icon3} alt='Prototyping'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Uprzemysłowienie</span>
                                <img className='competences-thirdSectionTableIcon' src={icon4} alt='Industrialization'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Produkcja </span>
                                <img className='competences-thirdSectionTableIcon' src={icon5} alt='Production'/>
                            </div>
                            <div className="competences-thirdSectionTableCell6">
                                <span className='boldText'>Logistyka</span>
                                <img className='competences-thirdSectionTableIcon' src={icon6} alt='Logistics'/>
                            </div>   
                    </div>
                    </div>
                <Footer />
            </>
        );
}

export default Competences;