import React from "react";
import Header from "../components_pl/Header";
import Footer from "../components_pl/Footer";
import ProductBox from "../components_en/ProductBox";
import TitleSection from "../components_en/TitleSection";
import '../styles/ProductCategories.css';
import '../styles/App.css';

import plum48VWhiteImg from '../graphics/products/plum-48v-white.jpg';
import plum230VWhiteImg from '../graphics/products/plum-230v-white.jpg';

function Plum () {
    return(
        <>
        <Header/>
        <TitleSection h1={'PLUM'} h2={''} height='300px'/>
        <div className='productCategories-secondSection'>
            <span className="productCategories-productGroupDescription">
            Innowacyjna lampa LED o najszerszym spektrum zastosowań, odpowiednia praktycznie dla każdej hodowli zwierząt. Lampa ta może być zamontowana na dowolnej powierzchni: suficie, ścianie, oprawach i klatkach. Plum łączy w sobie standardową żarówkę LED z oprawą oświetleniową, co istotnie redukuje koszty w planowanych inwestycjach. Wykorzystanie zewnętrznych balastów znacznie skraca czas instalacji. Warto zauważyć, że w lampie Plum nie używa się śrub, a montaż odbywa się za pomocą mechanizmu "klick".
            </span>
            <div id='productCategories-productsGrid'>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-48v-white'} productImg={plum48VWhiteImg} productTitle='Plum 48V' productSubtitle='White' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-48v-white-blue'} productImg={plum48VWhiteImg} productTitle='Plum 48V' productSubtitle='White + Blue' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-48v-white-red'} productImg={plum48VWhiteImg} productTitle='Plum 48V' productSubtitle='White + Red' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-110v-white'} productImg={plum230VWhiteImg} productTitle='Plum 110V' productSubtitle='White' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-230v-white-blue'} productImg={plum230VWhiteImg} productTitle='Plum 230V' productSubtitle='White + Blue' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-230v-white-red'} productImg={plum230VWhiteImg} productTitle='Plum 230V' productSubtitle='Digital White + Red' productCategory='productCategories-lamps'/>
                <ProductBox productPageHref={'/products/animal-centric-lighting/plum-products/plum-230v-white'} productImg={plum230VWhiteImg} productTitle='Plum 230V' productSubtitle='White' productCategory='productCategories-lamps'/>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default Plum;